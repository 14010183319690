<app-header>
    <jbm-view-title [caption]="'menu.log'|translate"
      [itemcount]="queryHttpHelper.paginationProps.itemCount"
      [parentLinkCaption]="'menu.settings'|translate"
      [parentLinkSlug]="'settings'"></jbm-view-title>
    <div class="toolbar float-end">
        <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
          [pageSize]="queryHttpHelper.paginationProps.pageSize"
          [page]="queryHttpHelper.paginationProps.page"
          (pageClick)="pageChange($event)"></jbm-pagination>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-filter-toggle [showFilter]="filtersVisible" (onToggle)="toggleFilters($event)"></jbm-filter-toggle>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-help-button [helpTopicID]="0"></jbm-help-button>
    </div>
</app-header>
<section class="module overflow-y">
    <jbm-table #table [classname]="'log padding'"
       [absolute]="true"
       [data]="logs"
       [size]="'xs'"
       [filterable]="true"
       [showFilters]="filtersVisible"
       [sortable]="true"
       [sortColumn]="queryHttpHelper.sortProps.name"
       [sortDirection]="queryHttpHelper.sortProps.direction"
       [hoverable]="true"
       [rowSelectable]="true"
       [columnDefs]="columnDefs"
       [actionDefs]="actionDefs"
       [template]="detailsTemplate"
       (rowCountChange)="rowCountChange($event)"
       (rowSelect)="rowSelect($event)"
       (sortChange)="sortChange($event)"
       (filterChange)="filterChange($event)">
    </jbm-table>
</section>
<ng-template #detailsTemplate let-data="data">
    <div [innerHTML]="detailsHTML" class="pl-4 pr-4 pt-3 pb-3">{{detailsHTML}}</div>
</ng-template>
