import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { CompanyService } from "../../data/company.service";
import { COCService} from "../../../../services/chamber-of-commerce/coc.service";
import { JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import { ModalService} from "../../../../components/JBM/JBMModal/service/modal.service";
import { TranslateService} from "../../../../services/multilingual/translate.service";
import { TokenService} from "../../../../services/auth/token.service";
import { JBMModalHeaderButton} from "../../../../components/JBM/Forms/JBMModalHeader/JBMModalHeader.component";
import {GroupRights} from "../../../users/data/interfaces/grouprights";

@Component({
  selector: 'form-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})

export class CompanyComponent implements OnInit {
  data: any;
  editable: boolean=false;
  language: string;
  buttons: JBMModalHeaderButton[]=[];
  userRights: GroupRights;

  @Output() afterSave=new EventEmitter();

  constructor(
    private Router: Router,
    private formBuilder: FormBuilder,
    private CompanyService: CompanyService,
    private COCService: COCService,
    public TranslateService: TranslateService,
    private JBMToastsService: JBMToastsService,
    private ModalService: ModalService,
    private TokenService: TokenService,
  ) {
    this.userRights = this.TokenService.getRightsByName('company');
    this.editable = this.userRights.AllowUpdate;
    this.language = this.TokenService.getLanguage();
    this.addControls();
  }

  form: FormGroup;
  isSubmitted: boolean=false;

  ngOnInit(): void {
    this.initData();
  }
  initData() {
    this.buttons.push( { name: 'history', visible: true, enabled: true,
      caption: this.TranslateService.GetTranslation('entity.history'), iconclass: 'fa fa-history' } );

    this.CompanyService.getData().subscribe(
        (data)=>this.data=data.data,(error)=>console.error(error),()=>this.setControlValues())
  }
  addControls() {
    this.form = this.formBuilder.group({
      coc: new FormControl('',[Validators.pattern('^[0-9]{8}')]),
      vat: new FormControl(''),
      city : new FormControl('',[Validators.required]),
      street: new FormControl('',[Validators.required]),
      no: new FormControl('',[Validators.required]),
      postcode: new FormControl('',[Validators.required, Validators.pattern('[1-9][0-9]{3}[\\s]?[A-Za-z]{2}')]),
      lon: new FormControl(''),
      lat: new FormControl(''),
    }, { updateOn: "blur" } );
  }

  get formControls() { return this.form.controls; }

  setControlValues() {
    this.form.controls.coc.setValue(this.data.coc);
    this.form.controls.vat.setValue(this.data.vat);
    this.form.controls.city.setValue(this.data.address_details.city);
    this.form.controls.street.setValue(this.data.address_details.street);
    this.form.controls.no.setValue(this.data.address_details.no);
    this.form.controls.postcode.setValue(this.data.address_details.postcode);
    this.form.controls.lon.setValue(this.data.address_details.lon);
    this.form.controls.lat.setValue(this.data.address_details.lat);
  }
  updateAddress(e) {
    let COCNumber=e.target.value;
    COCNumber=COCNumber.trim();
    if(!COCNumber || COCNumber.length<8)
      return false; // COCNumber not given/valid
    let coc_address;
    this.COCService.getAddress(COCNumber).subscribe(
        (data: any) => coc_address = data,()=>{},()=>{
          if(typeof coc_address === "string")
            return false; // Backend status is 500; error
          // Autocomplete address fields
          this.formControls.name.setValue(coc_address.name);
          this.formControls.full_name.setValue(coc_address.full_name);
          this.formControls.city.setValue(coc_address.city);
          this.formControls.street.setValue(coc_address.street);
          this.formControls.postcode.setValue(coc_address.postcode);
          this.formControls.no.setValue(coc_address.no);
        })
  }
  save() {
    this.isSubmitted = true;

    if(this.form.invalid)
      return;

    // Store company
    let data = {
      id: this.data.id,
      coc: this.formControls.coc.value,
      vat: this.formControls.vat.value,
      address_details_id: this.data.address_details_id,
      address_details: {
        city: this.formControls.city.value,
        street: this.formControls.street.value,
        postcode: this.formControls.postcode.value,
        no: this.formControls.no.value,
        lat: parseFloat(this.formControls.lat.value),
        lon: parseFloat(this.formControls.lon.value)
      },
    }
    this.CompanyService.update(data).subscribe(
        data=>{},error=>console.error(error),()=>this.showToast())
  }
  showToast() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
  }
  cancel() {
    this.Router.navigateByUrl('settings');
  }
}
