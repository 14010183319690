import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {TokenService} from "../../../services/auth/token.service";
import {ProductionReceiptsService} from "../data/production-receipts.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {PlantsService} from "../../company/data/plants.service";
import {OrdersService} from "../../projects/data/orders.service";
import {JBMSelectOption} from "../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {Subscription} from "rxjs";
import {QueryHttpHelper, SearchColumnType} from "../../../components/JBM/Helpers/QueryHttpHelper";
import {
    JBMTableActionDef,
    JBMTableColumnAlignment,
    JBMTableColumnDef,
    JBMTableColumnType,
    JBMTableComponent
} from "../../../components/JBM/JBMTable/JBMTable.component";
import {GroupRights} from "../../users/data/interfaces/grouprights";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";

@Component({
  selector: 'production-receipts',
  templateUrl: './production-receipts.component.html',
  styleUrls: ['./production-receipts.component.scss']
})
export class ProductionReceiptsComponent implements OnInit, OnDestroy {
  projectRights: GroupRights;
  recipeRights: GroupRights;
  language: string;
  receipts: any[]=[];
  order_id: number
  recipe_id: number;
  plantOptions: JBMSelectOption[]=[];
  plants_id: number=0;
  columnDefs: JBMTableColumnDef[];
  actionDefs: JBMTableActionDef[];

  queryHttpHelper: QueryHttpHelper;
  searchboxVisible = true;
  search = '';
  filtersVisible = false;
  loading = false;

  // Date range filtering
  fromDate: number=0;
  toDate: number=0;

  unsynchronised: number=0;

  count$: Subscription;

  timer: any;

  @ViewChild('table') table: JBMTableComponent;

  @ViewChild('orderTemplate') orderTemplate: TemplateRef<any>;
  @ViewChild('truckTemplate') truckTemplate: TemplateRef<any>;
  @ViewChild('recipeTemplate') recipeTemplate: TemplateRef<any>;
  @ViewChild('expandTemplate') expandTemplate: TemplateRef<any>;

  @ViewChild('modalRecipeDetails') modalRecipeDetails: ElementRef;

  constructor(
      private Router: Router,
      private TokenService: TokenService,
      private ProductionReceiptsService: ProductionReceiptsService,
      private OrdersService: OrdersService,
      private ModalService: ModalService,
      private JBMToastsService: JBMToastsService,
      private PlantsService: PlantsService,
      private TranslateService: TranslateService
  ) {
    this.language=this.TokenService.getLanguage();

    this.projectRights=this.TokenService.getRightsByName('projects');
    this.recipeRights=this.TokenService.getRightsByName('recipes');

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'orders_id', type: JBMTableColumnType.number, align: JBMTableColumnAlignment.left,
        header: this.TranslateService.GetTranslation('project.order') + ' ' + this.TranslateService.GetTranslation('ui.id'),
        sortable: true, filterable: true, width: 7.5 },
      { name: 'id', type: JBMTableColumnType.number, align: JBMTableColumnAlignment.left,
        header: this.TranslateService.GetTranslation('production.receipt') + ' ' + this.TranslateService.GetTranslation('ui.id'),
        sortable: true, filterable: true, width: 4.5 },
      { name: 'customer', header: this.TranslateService.GetTranslation('entity.customer'), sortable: true, filterable: true, width: 16 },
      { name: 'driver', header: this.TranslateService.GetTranslation('production.truck'),
        sortable: true, filterable: true, width: 14 },
      { name: 'execute_date', type: JBMTableColumnType.date, header: this.TranslateService.GetTranslation('datetime.date'),
        sortable: true, width: 5 },
      { name: 'loading_time', type: JBMTableColumnType.time, header: this.TranslateService.GetTranslation('datetime.start'), iconClassname: 'far fa-clock', width: 3.5 },
      { name: 'leave_plant_time', type: JBMTableColumnType.time, header: this.TranslateService.GetTranslation('datetime.stop'), iconClassname: 'far fa-clock', width: 3.5 },
      { name: 'receipt_amount', type: JBMTableColumnType.number, header: this.TranslateService.GetTranslation('production.produced'), align: JBMTableColumnAlignment.right,
        sortable: true, filterable: true, width: 7, className: 'pr-3' },
      { name: 'recipe', header: this.TranslateService.GetTranslation('entity.recipe'),
        sortable: false, filterable: true },
      { name: 'details', width: 3 }
    ];
    this.queryHttpHelper = new QueryHttpHelper();

    this.queryHttpHelper.addAliasedColumn('id','r.id');
    this.queryHttpHelper.addAliasedColumn('orders_id','r.orders_id');
    this.queryHttpHelper.addAliasedColumn('loading_time','r.loading_time');
    this.queryHttpHelper.addAliasedColumn('leave_plant_time','r.leave_plant_time');

    this.queryHttpHelper.addAliasedColumn('customer','c.name');
    this.queryHttpHelper.addAliasedColumn('driver','d.name');
    this.queryHttpHelper.addAliasedColumn('recipe','re.code');

    this.queryHttpHelper.setSortColumn('id',true);
    this.queryHttpHelper.addSearchColumn('orders_id', SearchColumnType.number);
    this.queryHttpHelper.addSearchColumn('id', SearchColumnType.number);
    this.queryHttpHelper.addSearchColumn('receipt_amount', SearchColumnType.number);
    this.queryHttpHelper.addSearchColumn('recipe', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('driver', SearchColumnType.string);
  }
  ngOnInit(): void {
    let plants=[];
    this.PlantsService.getData(null).subscribe(
        (data)=>plants=data.data,(error)=>console.error(error),()=>{
          if(plants.length>1) {
            this.plantOptions = this.PlantsService.getSelectOptions(plants);
            this.plantOptions.unshift({ key: '0', value: this.TranslateService.GetTranslation('ui.all')})
          }

          this.columnDefs[1].template = this.orderTemplate;
          this.columnDefs[4].template = this.truckTemplate;
          this.columnDefs[9].template = this.recipeTemplate;
          this.columnDefs[10].template = this.expandTemplate;

          this.queryHttpHelper.paginationProps.pageSize=this.table.getRowCount();

          this.countUnsynchronised();
          this.getData(true);
        }
    );
  }
  countUnsynchronised() {
    this.ProductionReceiptsService.getCountUnsynchronised().subscribe(
    (data)=>this.unsynchronised=data.data,(error)=>console.error(error))
  }
  getData(countRows:boolean=false) {
    this.loading = true;
    clearTimeout(this.timer);

    let params=this.setParams();
    this.ProductionReceiptsService.get(params).subscribe(
        (data)=>this.receipts=data.data,
        (error)=>console.error(error),
        ()=> {
          this.loading=false;
          this.formatData();
          if(!countRows)
            return;

          let params=this.setParams(true);
          this.count$=this.ProductionReceiptsService.get(params).subscribe(
            (data: any)=>this.queryHttpHelper.paginationProps.itemCount=data.data,
            (error)=>console.error(error),
            ()=>
              // Refresh view every minute (Periodically poll or server refresh)
              this.timer=setTimeout(()=> this.getData(true),60000)
          );
        }
    )
  }
  setParams(countRows=false) {
      let params=this.queryHttpHelper.getHttpParams(false);
      if(this.plants_id) params=params.append('plants_id', this.plants_id.toString());
      if(this.fromDate) {
          params=params.append('fromdate', this.fromDate.toString());
          params=params.append('todate', this.toDate.toString());
      }
      params=params.append('check_production_lines','1');
      if(countRows)
          params=params.append('count','1');

      return params;
  }
  formatData() {
    for(let receipt of this.receipts) {
        receipt.receipt_amount=receipt.receipt_amount.toString().replace('.',',') + ' ' + this.TranslateService.GetTranslation('quantity.m3');
    }
  }
  rowCountChange(rowCount) {
    this.queryHttpHelper.paginationProps.pageSize=rowCount;
    this.getData();
  }
  changePlant(event) {
    this.plants_id=parseInt(event.target.value);

    this.queryHttpHelper.clearFilters();
    for(let c=0; c<this.table.columnDefs.length; c++)
        this.table.columnDefs[c].filterValue='';
    this.queryHttpHelper.setSearchValue('');

    this.getData(true);
  }
  setDateRange(event) {
    this.fromDate=event.fromUnixTime;
    this.toDate=event.toUnixTime;
    this.getData(true);
  }
  undoDateRange() {
    this.fromDate=0;
    this.toDate=0;
    this.getData(true);
  }

  // Event handling
  pageChange(page) {
      this.queryHttpHelper.paginationProps.page = page;
      this.getData();
  }
  searchChange(event) {
      let value = event.value;
      const minlength = event.minlength;
      value = value.length < minlength ? '' : value;
      if (this.queryHttpHelper.getSearchValue() !== value) {
          this.queryHttpHelper.setSearchValue(value);
          this.getData(true);
      }
  }
  sortChange(event) {
      this.queryHttpHelper.setSortColumn(event.name, event.descending);
      this.getData();
  }
  toggleFilters(event) {
      this.filtersVisible = !this.filtersVisible;
      this.queryHttpHelper.clearFilters();
      for(let c=0; c<this.table.columnDefs.length; c++)
          this.table.columnDefs[c].filterValue='';
      this.queryHttpHelper.setSearchValue('');
      this.getData(true);
  }
  filterChange(event) {
      // Delete receipt id number filter when another filter becomes active
      if(event.name!=='receipt_id') {
          this.queryHttpHelper.deleteFilterItem('receipt_id');
          this.table.resetColumnFilter('receipt_id');
      }
      // Delete other filters when receipt id number filter becomes active
      if(event.name==='receipt_id') {
          this.queryHttpHelper.clearFilters();
          this.table.resetColumnFilters();
      }

      // Delete order id number filter when another filter becomes active
      if(event.name!=='orders_id') {
          this.queryHttpHelper.deleteFilterItem('orders_id');
          this.table.resetColumnFilter('orders_id');
      }
      // Delete other filters when order id number filter becomes active
      if(event.name==='orders_id') {
          this.queryHttpHelper.clearFilters();
          this.table.resetColumnFilters();
      }

      this.queryHttpHelper.setFilterItem(event.name, event.value);
      this.getData(true);
  }
  rowSelect(row) {
    if(!row.has_production_lines) return;
    this.table.toggleRowExpanded(row);
  }
  recipeDetails(order_id: number, recipe_id: number) {
      this.order_id=order_id;
      this.recipe_id=recipe_id;
      this.ModalService.open(this.modalRecipeDetails,'recipeDetails')
  }
  closeRecipe() {
      this.ModalService.close('recipeDetails')
  }
  redirectToOrder(order_id: number) {
    let order={ projects_id: 0 };
    this.OrdersService.getOne(order_id, this.language).subscribe(
        (data)=>order=data.data,
        (error)=>console.error(error),
        ()=>
            this.Router.navigateByUrl('projects/'+order.projects_id+'/orders/edit/'+order_id)
    )
  }
  confirmDelete(receipt_id: number) {
    this.ProductionReceiptsService.remove(receipt_id).subscribe(
        ()=>{},(error)=>console.error(error),
        ()=> {
            this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-deleted'));
            this.getData(true);
        }
    );
  }
  synchronise() {
      this.Router.navigateByUrl('production/synchronisation')
  }
  ngOnDestroy(): void {
    clearTimeout(this.timer);

    // Clean up observable subscriptions to avoid memory leaks
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
