<app-header>
    <jbm-view-title [caption]="'entity.recipes'|translate"
      [itemcount]="queryHttpHelper.paginationProps.itemCount"
      [parentLinkCaption]="'menu.settings'|translate"
      [parentLinkSlug]="'settings'">
    </jbm-view-title>
    <div class="toolbar">
        <jbm-create-button *ngIf="userRights.AllowCreate" (click)="create()"></jbm-create-button>
    </div>
    <div class="toolbar float-end">
        <jbm-spinner [loading]="loading"></jbm-spinner>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-toggle-button [caption]="'ui.hidden'|translate" [toggled]="!published" (onToggle)="togglePublished()"></jbm-toggle-button>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
          [pageSize]="queryHttpHelper.paginationProps.pageSize"
          [page]="queryHttpHelper.paginationProps.page"
          (pageClick)="pageChange($event)">
        </jbm-pagination>
        <jbm-searchbox #searchBox [id]="'search-box'" [visible]="!filtersVisible && searchboxVisble" [search]="search" [minlength]="2" (searchChange)="searchChange($event)"></jbm-searchbox>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-filter-toggle [showFilter]="filtersVisible" (onToggle)="toggleFilters($event)"></jbm-filter-toggle>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-help-button [helpTopicID]="0"></jbm-help-button>
    </div>
</app-header>
<section class="module">
    <jbm-table #table
      [absolute]="true"
      [classname]="'padding'"
      [data]="items"
      [filterable]="true"
      [showFilters]="filtersVisible"
      [searchMinLength]="2"
      [sortable]="true"
      [sortColumn]="queryHttpHelper.sortProps.name"
      [sortDirection]="queryHttpHelper.sortProps.direction"
      [hoverable]="true"
      [rowSelectable]="userRights.AllowUpdate"
      [columnDefs]="columnDefs"
      (rowCountChange)="rowCountChange($event)"
      (sortChange)="sortChange($event)"
      (filterChange)="filterChange($event)"
      (rowSelect)="rowSelect($event)">
    </jbm-table>
</section>

<ng-template #environmentsTemplate let-data="data">
    {{data.environments}}
</ng-template>

<ng-template #actionsTemplate let-data="data">
    <button *ngIf="userRights.AllowUpdate" type="button" class="btn btn-primary btn-sm float-start mr-1" (click)="edit(data.id);$event.stopPropagation()"><i class="far fa-edit"></i></button>
    <button *ngIf="userRights.AllowUpdate" type="button" class="btn btn-light btn-sm float-start mr-3" (click)="editArticles(data);$event.stopPropagation()">{{'financial.articles'|translate}}</button>
</ng-template>

<ng-template #publishedTemplate let-data="data">
    <div class="custom-control custom-switch float-start" (click)="$event.stopPropagation()">
        <input type="checkbox" class="custom-control-input" [disabled]="!userRights.AllowUpdate" id="published{{data.id}}" [checked]="data.published" (click)="togglePublishedState(data.id,!data.published)">
        <label class="custom-control-label" for="published{{data.id}}"></label>
    </div>
</ng-template>

<jbm-modal [id]="'recipe'" [className]="'recipe'"></jbm-modal>
<ng-template #modalRecipe let-modal>
    <recipe-form [id]="id" [activeTab]="activeTab" (onSave)="recipeSuccess()" (onClose)="recipeClose()"></recipe-form>
</ng-template>
<ng-template #modalArticles let-modal>
    <recipe-articles [recipe]="recipe" (onSave)="articlesSuccess()" (onClose)="articlesClose()"></recipe-articles>
</ng-template>

<jbm-toasts></jbm-toasts>
