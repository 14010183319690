export const subjectTypes = {
	recipes: 1,
	resources: 2,
	projects: 3,
	planning: 4,
	users: 5,
	settings: 6,
	customers: 7,
	customertypes: 8,
	translations: 9,
	articlegroups: 10,
	articles: 11,
	article_price_types: 12,
	article_prices: 14,
	article_pricelists: 15,
	customer_article_pricelists: 16,
	environments: 17,
	strengths: 18,
	consistencies: 19,
	concrete_prices: 20,
	concrete_pricelists: 21,
	customer_concrete_pricelists: 22,
	company: 23,
	plants: 24,
	usergroups: 25,
	orders: 26,
	order_articles: 27,
	presentations: 28,
	invoices: 29,
	media_files: 30,
	mail_settings: 31,
	text_placeholders: 32,
	resource_pricelists: 33,
	resource_prices: 34,
	contacts: 35,
	quotations: 36
}