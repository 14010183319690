import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../../shared/shared.module";
import { SharedCustomersModule} from "../../shared/customers/shared.customers.module";
import { SharedProjectsModule} from "../../shared/projects/shared.projects.module";
import { JBMViewModule } from "../../components/JBM/Views/jbmview.module";
import { JBMModalModule} from "../../components/JBM/JBMModal/jbmmodal.module";
import { JBMTableModule} from "../../components/JBM/JBMTable/jbmtable.module";
import { JBMButtonsModule} from "../../components/JBM/Buttons/jbmbuttons.module";
import { JBMFormModule} from "../../components/JBM/Forms/jbmform.module";
import { JBMDropdownsModule} from "../../components/JBM/Dropdowns/jbmdropdowns.module";
import { SelectorsModule} from "../../components/domain/selectors/selectors.module";
import { ProductionRoutingModule } from './production-routing.module';
import { ProductionReceiptsComponent} from "./datatable/production-receipts.component";
import { ProductionDetailsComponent } from './details/production-details.component';
import { RecipeDetailsComponent } from '../recipes/details/recipe-details.component';
import { SynchroniseReceiptsComponent} from "./synchronisation/synchronise-receipts.component";
import { SynchroniseOrderComponent } from './synchronisation/synchronise-order/synchronise-order.component';
import {LayoutModule} from '../../layout/layout.module';

@NgModule({
  declarations: [ProductionReceiptsComponent, ProductionDetailsComponent, RecipeDetailsComponent, SynchroniseReceiptsComponent,
	  SynchroniseOrderComponent],
    imports: [CommonModule, ProductionRoutingModule, SharedModule, SharedCustomersModule, SharedProjectsModule, JBMViewModule,
        JBMModalModule, JBMTableModule, JBMButtonsModule, JBMDropdownsModule, JBMFormModule, SelectorsModule, LayoutModule]
})
export class ProductionModule { }
