<app-header>
   <jbm-view-title
     [caption]="('menu.production'|translate)+('production.receipts'|translate|lowercase)+' '+('ui.synchronise'|translate|lowercase)"
     [itemcount]="queryHttpHelper.paginationProps.itemCount"
     iconClassname="industry"
     [parentLinkCaption]="('menu.production'|translate)+('production.receipts'|translate|lowercase)"
     [parentLinkSlug]="'production'"
   >
   </jbm-view-title>
   <div class="toolbar ml-4 float-start">
   </div>
   <div class="toolbar float-end">
      <jbm-spinner [loading]="loading"></jbm-spinner>
      <div class="toolbar-spacer-1 float-end"></div>
      <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
        [pageSize]="queryHttpHelper.paginationProps.pageSize"
        [page]="queryHttpHelper.paginationProps.page" (pageClick)="pageChange($event)">
      </jbm-pagination>
      <jbm-searchbox [visible]="searchboxVisible && !filtersVisible" [search]="queryHttpHelper.getSearchValue()"
        [minlength]="3" (searchChange)="searchChange($event)" [id]="'receipt-search'">
      </jbm-searchbox>
      <div class="toolbar-spacer-1"></div>
      <div class="btn-group">
         <jbm-filter-toggle [showFilter]="filtersVisible" (onToggle)="toggleFilters($event)"></jbm-filter-toggle>
      </div>
      <div class="toolbar-spacer-2 float-end"></div>
      <jbm-help-button [helpTopicID]="7"></jbm-help-button>
   </div>
</app-header>
<section class="module overflow-y synchronise-receipts">
   <div class="pt-1 pb-1">
      <div class="float-start pl-4">
         <button [disabled]="!ids.length" class="btn btn-primary" (click)="synchronise()">
            {{'ui.synchronise'|translate}}...
         </button>
         <button *ngIf="ids.length" class="btn btn-light ml-3" (click)="resetSelection()">
            {{'ui.unselect'|translate}}
         </button>
      </div>
      <div class="toolbar ml-4 mr-4 p-0 float-start">
         <label *ngIf="plantOptions.length>1">{{'company.plant'|translate}}</label>
         <select *ngIf="plantOptions.length>1" (change)="changePlant($event)" class="form-control form-control-sm form-select">
            <option *ngFor="let plant of plantOptions" value="{{plant.key}}">{{plant.value}}</option>
         </select>
      </div>
      <div class="float-end mr-4">
         <jbm-date-range-input (onSelectRange)="setDateRange($event)" (onUndo)="undoDateRange()"></jbm-date-range-input>
      </div>
   </div>
   <jbm-table #table
     [absolute]="true"
     [classname]="'padding'"
     [data]="receipts"
     [filterable]="true"
     [showFilters]="filtersVisible"
     [searchMinLength]="3"
     [sortable]="true"
     [sortColumn]="queryHttpHelper.sortProps.name"
     [sortDirection]="queryHttpHelper.sortProps.direction"
     [hoverable]="true"
     [rowSelectable]="true"
     [columnDefs]="columnDefs"
     [actionDefs]="actionDefs"
     [template]="details"
     (rowCountChange)="rowCountChange($event)"
     (sortChange)="sortChange($event)"
     (filterChange)="filterChange($event)"
     (rowSelect)="rowSelect($event)">
   </jbm-table>
</section>
<jbm-toasts></jbm-toasts>

<ng-template #checkTemplate let-data="data">
   <div class="mt-1">
      <input type="checkbox" [checked]="ids.indexOf(data.id)>-1">
   </div>
</ng-template>

<ng-template #truckTemplate let-data="data">
   {{data.truck_number}} <span *ngIf="data.driver">- {{data.driver}}</span>
</ng-template>

<ng-template #details let-data="data">
   <div class="pl-3 pr-3 pb-3 bg-light-darker">
      <production-details [receipt_id]="data.id"></production-details>
   </div>
</ng-template>

<jbm-modal [id]="'synchronise'" [className]="'synchronise-order'"></jbm-modal>
<ng-template #modalSyncOrder let-modal>
   <synchronise-order [receipts]="ids" (onSave)="saveSyncOrder()" (onClose)="closeSyncOrder()"></synchronise-order>
</ng-template>

