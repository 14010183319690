import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {PriceService} from "../../../../services/price/price.service";
import {SanitizationService} from "../../../../services/sanitization/sanitization.service";
import {OrdersService} from "../../data/orders.service";
import {OrderArticlesService} from "../../data/order-articles.service";
import {price_type_system_codes} from "../../data/order-articles.service";
import {article_usages} from "../../data/order-articles.service";
import {ArticlesService} from "../../../financial/data/articles.service";
import {ArticlePricesService} from "../../../financial/data/article-prices.service";
import {price_usage_subjects} from "../../../financial/data/price-usages.service";
import {RecipeArticlesService} from "../../../recipes/data/recipe_articles.service";
import {forkJoin, Subscription} from "rxjs";
import {HttpParams} from "@angular/common/http";
import {OrderArticle} from "../../data/interfaces/order-article";
import {GroupRights} from "../../../users/data/interfaces/grouprights";

@Component({
	selector: 'order-articles',
	templateUrl: './order-articles.component.html',
	styleUrls: [ './order-articles.component.scss' ]
})
export class OrderArticlesComponent implements OnInit, OnDestroy {
    items: any[] = [];
	orderArticle: OrderArticle;
	article: any;
	article_id: number = 0;
	subject: number=price_usage_subjects.order_article;
	priceInfo: any={price: 0.0, adjustment: 0.0};
	priceRules: any[]=[];
	userRights: GroupRights;

	createActive: boolean = false;
	editing: boolean = false;

	usage: number=article_usages.order;
	article_price_types_id: number=price_type_system_codes.M3;
	price: number = 0.0;
	code: string = '';
	amount: string = '';
	amountTouched: boolean = false;
	amountError: string = '';
	totalPrice: number = 0.0;

	items$: Subscription;
	count$: Subscription;

	loading: boolean = false;

	@Input() language: string;
	@Input() project_id: number;
	@Input() customer_id: number;
	@Input() order_id: number;
	@Input() order_amount: number;
	@Input() showPrices: boolean;

	@Output() onTotalPriceChange=new EventEmitter();

	constructor(
		private Router: Router,
		private OrdersService: OrdersService,
		private OrderArticlesService: OrderArticlesService,
		private ArticlesService: ArticlesService,
		private ArticlePricesService: ArticlePricesService,
		private RecipeArticlesService: RecipeArticlesService,
		private TokenService: TokenService,
		private TranslateService: TranslateService,
		private PriceService: PriceService,
		private SanitizationService: SanitizationService
	) {
		this.userRights = this.TokenService.getRightsByName('projects');
	}

	ngOnInit(): void {
		this.loading = true;

		let params=new HttpParams().append('recipe_articles','1');
		this.items$=this.OrderArticlesService.get(this.order_id, this.language, params).subscribe(
			(data)=>this.items=data.data,
			(error)=> {
				console.error(error)
				this.loading = false;
			},
		    ()=> {
				this.loading = false;
				this.updateTotalPrices();
			}
		)
	}
	setOrderAmount(order_amount: number) {
		if(order_amount!==this.order_amount)
			this.adjustRecipeArticleAmounts(order_amount);
		this.order_amount=order_amount;
		this.updateTotalPrices();
	}
	adjustRecipeArticleAmounts(new_amount: number) {
		for(let item of this.items) {
			if(item.article_price_types_id!==price_type_system_codes.PERCENT)
				item.amount=item.recipe_amount * new_amount;
		}
	}
	setRecipe(recipe_id: number) {
		let recipe_articles=[];
		this.RecipeArticlesService.getArticles(recipe_id, this.language).subscribe(
			(data)=>recipe_articles=data.data,
			(error)=>console.error(error),
			()=>{
				this.removeRecipeArticles();
				this.addRecipeArticles(recipe_articles);
			}
		)
	}
	removeRecipeArticles() {
		this.items=this.items.filter(function(item) {
			return item.usage!==article_usages.recipe;
		});
	}
	addRecipeArticles(recipe_articles) {

		// Build array with price calculation observables
		let observables=[];
		for(let recipe_article of recipe_articles) {
			let params=new HttpParams()
				.append('article_id',recipe_article.articles_id.toString())
				.append('project_id',this.project_id.toString())
				.append('customer_id',this.customer_id.toString());
			observables.push(this.ArticlePricesService.calculatePrice(params));
		}

		// Subscribe to and get the price calculations
		forkJoin(observables).subscribe((data: any) => {
			let i=0;
			for(let recipe_article of recipe_articles) {
				this.priceInfo=data[i].data;

				let amount=recipe_article.amount;
				if(recipe_article.article.article_price_types_id!==price_type_system_codes.PERCENT)
					amount=this.order_amount*recipe_article.amount;

				// Try to delete already added order article width same article as recipe article
				this.deleteItem(recipe_article.articles_id);

				let item= {
					id: 0,
					code: recipe_article.article.price_type_code,
					articles_id: recipe_article.articles_id,
					usage: article_usages.recipe,
					name: recipe_article.article.name,
					amount: amount,
					recipe_amount: recipe_article.amount,
					article_price_types_id: recipe_article.article.article_price_types_id,
					price: this.priceInfo.price
				}
				item['totalprice']=this.OrderArticlesService.calcTotalPrice(item.price, item.amount, item.article_price_types_id, this.order_amount);
				this.items.unshift( item );

				i++;
			}

			this.updateTotalPrices();
		});
	}
	updateTotalPrices() {
		let totalPrice=0.0;
		for(let item of this.items) {
			item.totalprice=this.OrderArticlesService.calcTotalPrice(item.price, item.amount, item.article_price_types_id, this.order_amount);
			totalPrice+=item.totalprice;
		}
		// Emit total price event
		this.onTotalPriceChange.emit({ totalPrice: totalPrice } );
	}
    // Create item
	startCreate() {
		if( this.editing ) this.cancelEditing();
		this.clearItemData();
		this.createActive = true;
	}
	createCancel() {
		this.createActive = false;
		this.clearItemData();
	}
	// Edit item
	startEditing(article_id: number) {
		if(this.createActive)
			this.createCancel();
		for(let item of this.items)
			if(item.articles_id===article_id) {
				if(item.editing) return false;  // Row already in edit mode
				item.editing=true;
			} else
				item.editing=false;
		this.editing=true;
		let data=this.getOriginalValues();
		this.article_id=data.articles_id;
		this.usage=data.usage;
		this.article_price_types_id=data.article_price_types_id;
		if(data.article_price_types_id===price_type_system_codes.PERCENT)
			this.amount=data.amount.toString();
		else
			this.amount=this.SanitizationService.amountFloatToStr(data.amount);
		this.price=data.price;
		this.calculateTotalPrice();

		setTimeout(()=> { (<HTMLInputElement>document.getElementById('amount-' + article_id.toString())).focus(); },100);
	}
	cancelEditing() {
		for(let item of this.items)
			item.editing = false;
		this.editing = false;
		this.clearItemData();
	}
	getOriginalValues() {
		for(let item of this.items)
			if(item.editing)
				return item;
		return null;
	}
	setItemData(article_id: number) {
		let amount = this.SanitizationService.checkAmountFloatStr(this.amount,0);
		if(this.article_price_types_id!=price_type_system_codes.PERCENT)
			amount=this.SanitizationService.floatRound2Decimals(amount);

		let exists=false;
		for(let item of this.items) {
			if(item.articles_id===article_id) {
				exists=true;
				item.amount=amount;
				item.totalprice=this.totalPrice;
				break;
			}
		}
		if(!exists) {
			let priceRules=[];
			let params=new HttpParams()
				.append('article_id',article_id.toString())
				.append('project_id',this.project_id.toString())
				.append('customer_id',this.customer_id.toString());

			this.ArticlePricesService.calculatePrice(params).subscribe(
				(data)=>priceRules=data.data.pricerules,(error)=>console.error(error),()=> {
					this.items.push( { id: 0, name: this.article.name, description: this.article.description, orders_id: this.order_id, articles_id: article_id,
						code: this.code, article_price_types_id: this.article_price_types_id, usage: article_usages.order,
						price: this.price, priceRules: priceRules, amount: amount, totalprice: this.totalPrice }
					)
				}
			);
		}

		this.updateTotalPrices();
	}
	clearItemData() {
		this.orderArticle=this.OrderArticlesService.getEmpty();
		this.article_id=0;
		this.usage=article_usages.order;
		this.article_price_types_id=price_type_system_codes.M3;
		this.priceRules=[];
		this.amount = '';
		this.amountTouched = false;
		this.amountError = '';
	}
	setArticle(order_id: number, article) {
		this.article=article;
		this.article_id=article.id;
		this.usage=article_usages.order;
		this.article_price_types_id=article.article_price_types_id;
		if(this.article_id===0) {
			this.price=0;
			this.code='';
			return;
		}
		this.price = article.price;
		this.code = article.price_type_code;

		this.calculateTotalPrice();

		let params=new HttpParams()
			.append('article_id',this.article_id.toString())
			.append('project_id',this.project_id.toString())
			.append('customer_id',this.customer_id.toString());

		this.ArticlePricesService.calculatePrice(params).subscribe(
			(data)=>this.priceRules=data.data.pricerules,(error)=>console.error(error));

		if(this.createActive) {
			setTimeout(()=> {
				(<HTMLInputElement>document.getElementById('amount-create' + order_id.toString())).focus();
			},100);
		}
	}
	validateArticle() {
		return this.article_id > 0;
	}
	onChangeAmount() {
		this.amountTouched = true;
		this.calculateTotalPrice();
	}
	validateAmount() {
		let amount = this.SanitizationService.checkAmountFloatStr(this.amount,0);
		if( amount<1 || (this.article_price_types_id===price_type_system_codes.PERCENT && amount>100)) {
			this.amountError=this.TranslateService.GetTranslation('ui.invalid');
			return false;
		}
		return amount > 0;
	}
	calculateTotalPrice() {
		let amount = this.SanitizationService.checkAmountFloatStr(this.amount,0);
		amount=this.SanitizationService.floatRound2Decimals(amount);
		this.totalPrice=this.OrderArticlesService.calcTotalPrice(this.price, amount, this.article_price_types_id, this.order_amount);
	}

	// Save item data
	createItem() {
		this.setItemData(this.article_id);
		this.createCancel();
	}
	updateItem() {
		let originalValues=this.getOriginalValues();
		this.setItemData(originalValues.articles_id);
		this.cancelEditing();
	}
	deleteItem(article_id: number) {
		this.items=this.items.filter(item => item.articles_id!==article_id);
		this.updateTotalPrices();
	}
	save(order_id: number=null) {
		let order_articles=[];
		for(let item of this.items) {
			order_articles.push( {
				id: item.id,
				orders_id: order_id,
				articles_id: item.articles_id,
				description: item.description,
				amount: item.amount,
				price: item.price,
				usage: item.usage,
				article_price_types_id: item.article_price_types_id,
				article_display: item.article_display,
				totalprice: item.totalprice
			});
		}
	    this.OrderArticlesService.massUpdate(order_id, order_articles).subscribe(
		  ()=>{},(error)=>console.error(error)
		);
	}
	ngOnDestroy(): void {
		// Clean up observable subscriptions to avoid memory leaks
		if( this.items$ != undefined ) this.items$.unsubscribe();
		if( this.count$ != undefined ) this.count$.unsubscribe();
	}
}
