import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SocketIoModule, SocketIoConfig} from "ngx-socket-io";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// HTTP requests
import { AuthInterceptor } from './services/auth/authconfig.interceptor';

// State service / provider
import { StateService } from './services/storage/state.service';

// User Interface
import {NgbAccordion, NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import { LowerCasePipe } from "@angular/common";
import { DatePipe } from '@angular/common';
import { ChangesGuard } from './guards/changes-guard';

// App domain
import { TranslateService } from './services/multilingual/translate.service';
import { SharedModule } from './shared/shared.module';
import { LayoutModule} from './layout/layout.module';
import { DashboardModule} from './features/dashboard/dashboard.module';
import { CompanyModule } from './features/company/company.module';
import { CustomersModule } from './features/customers/customers.module';
import { QuotationsModule} from "./features/quotations/quotations.module";
import { ProjectsModule } from './features/projects/projects.module';
import { PresentationsModule } from './features/presentations/presentations.module';
import { ContactsModule} from "./features/contacts/contacts.module";
import { PlanningModule } from './features/planning/planning.module';
import { ProductionModule} from "./features/production/production.module";
import { LogisticsModule} from "./features/logistics/logistics.module";
import { InvoicingModule } from './features/invoicing/invoicing.module';
import { FinancialModule } from './features/financial/financial.module';
import { RecipesModule} from "./features/recipes/recipes.module";
import { TranslationsModule } from './features/translations/translations.module';
import { ReportsModule } from './features/reports/reports.module';
import { SettingsModule } from './features/settings/settings.module';
import { HelpCentreModule} from './features/helpcentre/helpcentre.module';
import { UsersModule } from './features/users/users.module';
import { TestModule } from "./test/test.module";
import { ExternalLayoutComponent } from './layout/external-layout/external-layout.component';

import {RecipeAppModule} from "./apps/recipeApp/recipe-app.module";

@NgModule({
  declarations: [AppComponent, ExternalLayoutComponent],
  imports: [BrowserModule, BrowserAnimationsModule, HttpClientModule, AppRoutingModule, NgbModule,
    FormsModule, ReactiveFormsModule, LayoutModule, DashboardModule, CompanyModule, CustomersModule, QuotationsModule,
    ProjectsModule, PlanningModule, PresentationsModule, ContactsModule, RecipesModule, InvoicingModule, ProductionModule,
    LogisticsModule, FinancialModule, TranslationsModule, HelpCentreModule, ReportsModule, SettingsModule, UsersModule,
    RecipeAppModule, TestModule, NgSelectModule,
    SharedModule.forRoot()
    //SocketIoModule.forRoot( { url: environment.SocketIO_EndPoint })
  ],
  providers: [
    DatePipe, LowerCasePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
	NgbActiveModal, NgbAccordion, ChangesGuard, StateService
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor( TranslateService: TranslateService) {
    TranslateService.LoadTranslations();
  }
}
