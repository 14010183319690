import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDatepickerKeyboardService,
  NgbDateStruct
} from "@ng-bootstrap/ng-bootstrap";
import {CustomDatepickerI18n} from "../../../../services/NgbDatepicker/custom-datepicker-i18n.service";
import {CustomAdapter} from "../../../../services/NgbDatepicker/custom-adapter.service";
import {CustomDateParserFormatter} from "../../../../services/NgbDatepicker/custom-dateparser-formatter.service";
import {CustomKeyboardService} from "../../../../services/NgbDatepicker/custom-keyboard-service";
import {DatetimeService} from "../../../../services/datetime/datetime.service";

@Component({
  selector: 'jbm-date-range-input',
  templateUrl: './JBMDateRangeInput.component.html',
  styleUrls: ['./JBMDateRangeInput.component.scss'],
  providers: [
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}, // define custom NgbDatepickerI18n provider
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    {provide: NgbDatepickerKeyboardService, useClass: CustomKeyboardService}
  ]
})
export class JBMDateRangeInputComponent implements OnInit {
  picker_from: NgbDateStruct=null;
  picker_to: NgbDateStruct=null;

  constructor(private DatetimeService: DatetimeService) { }

  @Input() id: string='JBMDateRangeInput';
  @Input() setToday: boolean=false;
  @Input() from_date: string;
  @Input() to_date: string;
  @Input() sm: boolean=false; // Condensed height
  @Input() class: string='';
  @Input() style: string='';

  @Output() onSelectRange=new EventEmitter();
  @Output() onUndo=new EventEmitter();

  ngOnInit(): void {
    if(this.from_date==undefined) this.from_date='';
    if(this.to_date==undefined) this.to_date='';
    if(this.setToday==undefined) this.setToday=false;

    if(this.setToday) this.setCurrentDate();

    if(this.from_date && this.DatetimeService.validateDateStr(this.from_date))
      this.picker_from=this.dateStrToDateStruct(this.from_date);
    if(this.to_date && this.DatetimeService.validateDateStr(this.to_date))
      this.picker_to=this.dateStrToDateStruct(this.to_date);
  }
  setCurrentDate() {
    this.from_date=this.DatetimeService.formatDate({
      day: this.DatetimeService.getCurrentDay(),
      month: this.DatetimeService.getCurrentMonth(),
      year: this.DatetimeService.getCurrentYear()
    },'dmy');
    this.to_date=this.from_date;
    this.setRange();
  }
  fromIsvalid() {
    if(!this.from_date)
      return true;
    return this.DatetimeService.validateDateStr(this.from_date);
  }
  toIsvalid() {
    if(!this.to_date)
      return true;
    return this.DatetimeService.validateDateStr(this.to_date);
  }
  rangeValid() {
    let fromYMD=this.DatetimeService.dateDMYToYMD(this.from_date)
    let toYMD  =this.DatetimeService.dateDMYToYMD(this.to_date);
    return (fromYMD<=toYMD);
  }
  setRange() {
    let fromYMD=this.DatetimeService.dateDMYToYMD(this.from_date);
    let toYMD=this.DatetimeService.dateDMYToYMD(this.to_date);

    let fromParts=this.DatetimeService.getDatePartsDMY(this.from_date);
    let toParts=this.DatetimeService.getDatePartsDMY(this.to_date);

    let fromUnixTime=this.DatetimeService.getUnixTimeFromDateParts(fromParts);          // Start of the day
    let toUnixTime  =this.DatetimeService.getUnixTimeFromDateParts(toParts) + 86400000; // End of the day

    this.onSelectRange.emit({
      fromDMY: this.from_date, toDMY: this.to_date,
      fromYMD: fromYMD, toYMD: toYMD,
      fromUnixTime: fromUnixTime, toUnixTime: toUnixTime
    })
  }
  undo() {
    this.from_date=null;
    this.picker_from=null;
    this.to_date=null;
    this.picker_to=null;
    this.onUndo.emit();
  }
  dateStrToDateStruct(date: string): NgbDateStruct {
    let parts=this.DatetimeService.getDatePartsDMY(date);
    if(parts.day===0 && parts.month===0 && parts.year===0)
      return null;
    return { day: parts.day, month: parts.month, year: parts.year }
  }
}
