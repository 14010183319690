<div class="jbm-date-range-input {{class}}" style="{{style}}">
   <div class="from float-left">
      <label for="date-start" class="float-start mr-2">{{'ui.from'|translate}}</label>
      <input type="text" id="date-start" autocomplete="off" class="form-control form-control-sm float-start" [(ngModel)]="from_date"
        [placement]="'bottom-left'" ngbDatepicker #dateFrom="ngbDatepicker" [weekdays]="true"
        [attr.maxlength]="10" [ngClass]="{'is-invalid': !fromIsvalid(), 'ng-valid': from_date!=null && fromIsvalid(), 'form-control-sm': sm }">
      <button type="button" tabindex="-1" class="btn btn-link float-start pl-2" [ngClass]="{'form-control-sm': sm}" (click)="dateFrom.toggle()">
         <i class="fa fa-calendar"></i>
      </button>
   </div>
   <div class="to float-start">
      <label for="date-end" class="float-start mr-2">{{'ui.up-to-and-including'|translate}}</label>
      <input type="text" id="date-end" autocomplete="off" class="form-control form-control-sm float-start" [(ngModel)]="to_date"
        [placement]="'bottom'" ngbDatepicker #dateTo="ngbDatepicker" [weekdays]="true"
        [attr.maxlength]="10" [ngClass]="{'is-invalid': !toIsvalid(), 'ng-valid': to_date!=null && toIsvalid(), 'form-control-sm': sm }">
      <button type="button" tabindex="-1" class="btn btn-link float-start pl-2" [ngClass]="{'form-control-sm': sm}" (click)="dateTo.toggle()">
         <i class="fa fa-calendar"></i>
      </button>
   </div>
   <td class="buttons float-start">
      <button [disabled]="from_date==null || to_date==null || !fromIsvalid() || !toIsvalid() || !rangeValid()" type="button" class="btn btn-light" [ngClass]="{'btn-sm': sm}" (click)="setRange()">
         <i class="fas fa-check"></i>
      </button>
      <button type="button" class="btn btn-light" [ngClass]="{'btn-sm': sm}" (click)="undo()">
         <i class="fas fa-undo"></i>
      </button>
   </td>
</div>
<!--   <table class="table">
      <tr>
         <td class="label">{{'ui.from'|translate}}</td>
         <td class="input">
            <input type="text" autocomplete="off" class="form-control form-control-sm" [(ngModel)]="from_date"
              [placement]="'bottom-left'" ngbDatepicker #dateFrom="ngbDatepicker" [weekdays]="true"
              [attr.maxlength]="10" [ngClass]="{'is-invalid': !fromIsvalid(), 'form-control-sm': sm }">
         </td>
         <td class="button pr-3">
            <button type="button" tabindex="-1" class="btn btn-link" [ngClass]="{'form-control-sm': sm}" (click)="dateFrom.toggle()">
               <i class="fa fa-calendar"></i>
            </button>
         </td>
         <td class="label">{{'ui.up-to-and-including'|translate}}</td>
         <td class="input">
            <input type="text" autocomplete="off" class="form-control form-control-sm" [(ngModel)]="to_date"
              [placement]="'bottom'" ngbDatepicker #dateTo="ngbDatepicker" [weekdays]="true"
              [attr.maxlength]="10" [ngClass]="{'is-invalid': !toIsvalid(), 'form-control-sm': sm }">
         </td>
         <td class="button pr-3">
            <button type="button" tabindex="-1" class="btn btn-link" [ngClass]="{'form-control-sm': sm}" (click)="dateTo.toggle()">
               <i class="fa fa-calendar"></i>
            </button>
         </td>
         <td class="buttons">
            <button [disabled]="!fromIsvalid() || !toIsvalid() || !rangeValid()" type="button" class="btn btn-light" [ngClass]="{'btn-sm': sm}" (click)="setRange()">
               <i class="fas fa-check"></i>
            </button>
            <button type="button" class="btn btn-light" [ngClass]="{'btn-sm': sm}" (click)="undo()">
               <i class="fas fa-undo"></i>
            </button>
         </td>
      </tr>
   </table>
</div>-->
