import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {LogService} from "../../../services/activity-log/log.service";
import {LogSubjectService} from "../../../services/activity-log/logsubject.service";
import {ActivitiesService} from "../../../services/activity-log/activities.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {TokenService} from "../../../services/auth/token.service";
import {
  JBMTableActionDef,
  JBMTableColumnAlignment,
  JBMTableColumnDef,
  JBMTableColumnType, JBMTableComponent,
  JBMTableFilter
} from "../../../components/JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../components/JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {elementSelectors} from "@angular/cdk/schematics";

@Component({
  selector: 'app-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit, OnDestroy {
  userRights: any;
  logs: any[];
  language: string=this.TokenService.getLanguage();
  columnDefs: JBMTableColumnDef[];
  actionDefs: JBMTableActionDef[];
  detailsHTML: string='';

  logs$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisble: boolean=true;
  search: string='';
  filtersVisible: boolean=false;

  @ViewChild('table') table: JBMTableComponent;
  @ViewChild('detailsTemplate') detailsTemplate: TemplateRef<any>;

  constructor(
      public LogService: LogService,
      public LogSubjectService: LogSubjectService,
      private ActivitiesService: ActivitiesService,
      private TokenService: TokenService,
      public TranslateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('customers');

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('timestamp', true);
    this.queryHttpHelper.addSearchColumn('full_name', SearchColumnType.string);

    this.columnDefs = [
      {
        name: 'id',
        type: JBMTableColumnType.id,
      },
      {
        name: 'full_name',
        header: this.TranslateService.GetTranslation('login.username'),
        filterable: true,
        width: 11
      },
      {
        name: 'subject_type',
        type: JBMTableColumnType.string,
        header: this.TranslateService.GetTranslation('log.subject'),
        filterable: true,
        width: 9,
        align: JBMTableColumnAlignment.left
      },
      {
        name: 'description',
        header: this.TranslateService.GetTranslation('entity.description'),
      },
      {
        name: 'activity',
        header: this.TranslateService.GetTranslation('log.activity'),
        filterable: true,
        width: 8
      },
      {
        name: 'details',
        type: JBMTableColumnType.panelToggle,
        header: this.TranslateService.GetTranslation('ui.details'),
        width: 8
      },
      {
        name: 'timestamp',
        type: JBMTableColumnType.datetime,
        header: this.TranslateService.GetTranslation('entity.timestamp'),
        sortable: true,
        width: 8
      }
    ];

    this.queryHttpHelper.addSearchColumn('subject_type', SearchColumnType.number);
    this.setActivityFilterValues();
    this.setSubjectFilterValues();

    setTimeout(()=> {
      this.queryHttpHelper.paginationProps.pageSize=this.table.getRowCount();
      this.getData(true);
    },100);
  }
  setActivityFilterValues() {
    this.columnDefs[4].filterValues = this.ActivitiesService.getActivityItems();
  }
  setSubjectFilterValues() {
    this.columnDefs[2].filterValues = this.LogSubjectService.asKeyValueArray();
  }
  getData(countRows:boolean=false) {
    let params=this.queryHttpHelper.getHttpParams(false);
    params=params.append('language',this.language);
    this.logs$=this.LogService.getData(params).subscribe(
        (data: any) => this.logs = data.data,()=>{},()=>{

          // Set subject and translated activity description
          for(let l=0; l<this.logs.length; l++) {
            this.logs[l].subject_type=this.LogSubjectService.getTranslatedSubjectSingular(this.logs[l].subject_type);
            this.logs[l].activity=this.TranslateService.GetTranslation('userrights.'+this.logs[l].activity);
          }

          if(countRows) {
            let params=this.queryHttpHelper.getHttpParams(true);
            this.count$=this.LogService.getData(params).subscribe(
                (data: any) => {this.queryHttpHelper.paginationProps.itemCount = parseInt(data.data); });
          }
        } );
  }
  rowCountChange(rowCount) {
    this.queryHttpHelper.paginationProps.pageSize=rowCount;
    setTimeout(()=>this.getData(),1000);
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  toggleFilters(event) {
    this.filtersVisible=!this.filtersVisible;
    this.queryHttpHelper.setSearchValue('');
    this.getData(true);
  }
  filterChange(event) {
    this.queryHttpHelper.setFilterItem(event.name, event.value);
    this.getData(true);
  }
  rowSelect(event) {
    this.detailsHTML='';
    this.iterateObject(event.properties);
    this.table.toggleRowExpanded(event);
  }
  iterateObject(object) {
    for(let property in object) {
      if(typeof object[property]=='object') {
        // todo; For now just too much sub objects for several entities!
       // this.detailsHTML+=this.iterateObject(object[property]);
      } else {
        this.detailsHTML+=property.charAt(0).toUpperCase() + property.slice(1) + ': ';
        this.detailsHTML+=object[property] || this.TranslateService.GetTranslation('ui.empty');
        this.detailsHTML+='&nbsp;&nbsp;';
      }
    }
    return this.detailsHTML;
  }
  ngOnDestroy(): void {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.logs$!=undefined) this.logs$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
