<div class="mb-2">
    <b>{{'order.articles'|translate}}</b>&nbsp;&nbsp;
    <button *ngIf="!createActive" class="btn btn-primary btn-sm round" (click)="startCreate()"><i class="fa fa-plus"></i></button>
</div>
<div class="header-spacer-2 float-end"></div>
<jbm-spinner [loading]="loading"></jbm-spinner>
<div class="table-responsive bg-white mb-3">
    <table class="table table-sm table-row-selectable table-hover order">
        <thead>
        <tr>
            <th [hidden]="true"></th>
            <th jbm-table-column class="article" [name]="'article'" [header]="'order.article'|translate" [sortdirection]="'+'"></th>
            <th jbm-table-column class="amount" [name]="'amount'" [header]="'order.amount'|translate" [width]="4" [align]="2"></th>
            <th jbm-table-column class="type pl-0" [name]="'article_price_type'" [width]="5"></th>
            <th *ngIf="showPrices" jbm-table-column class="align-right pr-3" [name]="'price'" [header]="'article.price'|translate" [width]="6"></th>
            <th *ngIf="showPrices" jbm-table-column class="totalPrice pr-3" [name]="'totalPrice'" [header]="'ui.total'|translate" [width]="6" [align]="2"></th>
            <th class="action-buttons" style="width: 7rem"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="createActive" class="edit-active">
            <th [hidden]="true"></th>
            <td class="pr-3">
                <order-article-selector [id]="'article-selector'" [projects_id]="project_id" [orders_id]="order_id" [articles_id]="article_id"
                  [required]="true" [labelStacked]="false" [template]="articleselect"
                  (articleSelected)="setArticle(order_id, $event)">
                </order-article-selector>
            </td>
            <td><input float-only-input [disabled]="!this.article_id" [id]="'amount-create'+order_id" class="form-control form-control-sm amount" type="text" autocomplete="off" [(ngModel)]="amount"
              (change)="onChangeAmount()" [ngClass]="{'ng-valid':validateAmount(), 'is-invalid':this.amountTouched && !validateAmount()}">
                <div *ngIf="amountError" class="invalid-feedback">{{amountError}}</div>
            </td>
            <td class="pl-0"><label class="value">{{code}}</label></td>
            <td *ngIf="showPrices" class="align-right align-top pr-3">
                <jbm-pricerules-breakdown-popover [price]="price" [pricerules]="priceRules"></jbm-pricerules-breakdown-popover>
            </td>
            <td *ngIf="showPrices" class="align-right pr-3"><label class="value">{{totalPrice|money}}</label></td>
            <td class="editable actions align-right">
                <jbm-save-button [disabled]="!validateArticle() || !validateAmount()" class="mr-1" [showCaption]="false" (click)="createItem()"></jbm-save-button>
                <jbm-cancel-button [showCaption]="false" (click)="createCancel()"></jbm-cancel-button>
            </td>
        </tr>
        <tr *ngFor="let item of items" (click)="startEditing(item.articles_id)" [ngClass]="{ 'edit-active': item.editing }">
            <td [hidden]="true"></td>
            <td [ngClass]="{editable: item.editing}"><label class="value">
                {{item.description}}
                <span *ngIf="item.usage==0" class="badge bg-light text-dark ml-2">{{'entity.recipe'|translate}}{{'financial.article'|translate|lowercase}}</span>
            </label></td>
            <td *ngIf="item.editing && item.article_price_types_id!=58" class="editable">
                <input float-only-input class="form-control form-control-sm align-right" [id]="'amount-'+item.articles_id" type="text" autocomplete="off"
                  [(ngModel)]="amount" (change)="onChangeAmount()" [ngClass]="{'ng-valid':validateAmount(), 'is-invalid':this.amountTouched && !validateAmount()}">
                <div *ngIf="amountError" class="invalid-feedback">{{amountError}}</div>
            </td>
            <td *ngIf="item.editing && item.article_price_types_id==58" class="editable">
                <input digits-only-input class="form-control form-control-sm align-right" [id]="'amount-'+item.articles_id" type="text" autocomplete="off"
                  [(ngModel)]="amount" [maxLength]="3" [minLength]="1" (change)="onChangeAmount()" [ngClass]="{'ng-valid':validateAmount(), 'is-invalid':this.amountTouched && !validateAmount()}">
                <div *ngIf="amountError" class="invalid-feedback">{{amountError}}</div>
            </td>
            <td *ngIf="!item.editing" class="align-right"><label class="value">{{item.amount|float}}</label></td>
            <td class="pl-0" [ngClass]="{editable: item.editing}"><label class="value">{{item.code}}</label></td>
            <td *ngIf="showPrices" class="align-right pr-3" [ngClass]="{editable: item.editing}">
               <jbm-article-pricerules-breakdown-popover *ngIf="item.id" [subject]="subject" [subject_id]="item.id" [price]="item.price"></jbm-article-pricerules-breakdown-popover>
               <jbm-pricerules-breakdown-popover *ngIf="!item.id" [price]="item.price" [pricerules]="item.priceRules"></jbm-pricerules-breakdown-popover>
            </td>
            <td *ngIf="showPrices && !item.editing" class="align-right pr-3">
                <label class="value">{{item.totalprice|money}}</label>
            </td>
            <td *ngIf="showPrices && item.editing" class="align-right pr-3 editable"><label class="value">{{totalPrice|money}}</label></td>
            <td *ngIf="!item.editing" class="action-buttons align-right">
                <button *ngIf="userRights.AllowUpdate" class="btn btn-primary btn-sm mr-2" (click)="startEditing(item.articles_id)"><i class="far fa-edit"></i></button>
                <!-- Deletion allowed if not recipe article -->
                <jbm-confirm-delete-dropdown *ngIf="item.usage==1"
                  [subject]="('project.order'|translate)+' '+('order.article'|translate|lowercase)"
                  [placement]="'top-right'"
                  (onConfirm)="deleteItem(item.articles_id)">
                </jbm-confirm-delete-dropdown>
            </td>
            <td *ngIf="item.editing" class="editable action-buttons align-right">
                <jbm-save-button [disabled]="!validateArticle() || !validateAmount()" class="mr-1" [showCaption]="false" (click)="updateItem()"></jbm-save-button>
                <jbm-cancel-button [showCaption]="false" class="mr-2" (click)="cancelEditing()"></jbm-cancel-button>
            </td>
        </tr>
        </tbody>
    </table>
    <jbm-empty-view [emptyView]="items.length==0 && !createActive"></jbm-empty-view>
</div>
<ng-template #articleselect let-article="article">
    <div *ngIf="article">
        {{article.name}}
    </div>
</ng-template>
