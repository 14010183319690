import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {ActivatedRoute} from "@angular/router";
import {PDFTemplatesService} from "../data/pdf_templates.service";
import {PlaceholdersService} from "../data/placeholders.service";
import {Presentation} from "../data/interfaces/presentation";
import {TokenService} from "../../../services/auth/token.service";
import {PDFTemplate} from "../data/interfaces/pdf_template";
import {PdfService} from "../../../services/pdf-make/pdf-service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {LocalStorageService} from "../../../services/storage/localstorage.service";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";

const localStorageKeys = { primary: 'pdf_editor_primary_size', secondary: 'pdf_editor_secondary_size' }

@Component({
  selector: 'pdf-template-editor',
  templateUrl: './pdf-template-editor.component.html',
  styleUrls: ['./pdf-template-editor.component.scss'],
  providers: [PdfService]
})
export class PdfTemplateEditorComponent implements OnInit {
  language: string;
  presentation: Presentation;
  PDFTemplate: PDFTemplate;
  showPreview: boolean=true;
  panel_primary_width: number=40;
  panel_secondary_width: number=60;
  editorOptions: any= { lineNumbers: true, theme: 'default', mode: {name: "javascript", json: true} }
  editorError: string='';
  showPlaceholdersPicker: boolean=false;
  activeEditorSection: number=1;
  template: any;
  placeholders: any[]=[];
  allocation: number=0;
  headerStr: string;
  bodyStr: string;
  groupStr: string;
  rowStr: string;
  footerStr: string;
  stylesStr: string;

  @ViewChild('editorHeader') private editorHeader;
  @ViewChild('editorBody') private editorBody;
  @ViewChild('editorGroup') private editorGroup;
  @ViewChild('editorRow') private editorRow;
  @ViewChild('editorFooter') private editorFooter;
  @ViewChild('editorStyles') private editorStyles;

  constructor(
      private Router: Router,
      private ActivatedRoute: ActivatedRoute,
      private PDFTemplatesService: PDFTemplatesService,
      private PdfService: PdfService,
      private PlaceholdersService: PlaceholdersService,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private LocalStorageService: LocalStorageService,
      private JBMToastsService: JBMToastsService
  ) {
    this.language=this.TokenService.getLanguage();
    this.presentation=this.ActivatedRoute.snapshot.data.presentation.data;
    this.template=JSON.stringify({});
  }
  ngOnInit(): void {
    this.PDFTemplatesService.getOne(this.language, this.presentation.id).subscribe(
        (data)=>this.PDFTemplate=data.data as PDFTemplate,
        (error)=>console.error(error),
        ()=>{
          this.template=this.PDFTemplate.template;
//          console.log(this.template);
          this.headerStr=JSON.stringify(this.template.header,null,2);
          this.bodyStr=JSON.stringify(this.template.content,null,2);
          this.footerStr=JSON.stringify(this.template.footer,null,2);
          this.stylesStr=JSON.stringify(this.template.styles,null,2);

          if(this.PDFTemplate.group_template===null)
            this.PDFTemplate.group_template={};
          if(this.PDFTemplate.row_template===null)
            this.PDFTemplate.row_template={};
          // Convert optional group and row templates
          this.groupStr=JSON.stringify(this.PDFTemplate.group_template,null,2);
          this.rowStr=JSON.stringify(this.PDFTemplate.row_template,null,2);

          this.createPDF();
          this.initPanelSizes();
        }
    )
  }
  initPanelSizes() {
    if(this.LocalStorageService.hasKey( localStorageKeys.primary ))
      this.panel_primary_width=this.LocalStorageService.get( localStorageKeys.primary );
    if(this.LocalStorageService.hasKey( localStorageKeys.secondary ))
      this.panel_secondary_width=this.LocalStorageService.get( localStorageKeys.secondary );
  }
  splitterDragEnd(unit: string, event) {
    // Store new panel sizes in localstorage
    this.LocalStorageService.set( localStorageKeys.primary, event.sizes[0]);
    this.LocalStorageService.set( localStorageKeys.secondary, event.sizes[1]);
  }

  togglePreview() {
    this.showPreview=!this.showPreview;
    if(this.showPreview)
      this.createPDF();
  }
  editorSectionChange(event) {
    this.showPlaceholdersPicker=false;
    this.activeEditorSection=-1;
    if(event.nextState)
      // Will be opened
      this.activeEditorSection=parseInt(event.panelId.replace('panel-',''));
  }

  // Placeholders
  insertPlaceholders(activeEditorSection: number) {
    this.allocation=activeEditorSection;
    this.getPlaceholders();
  }
  getPlaceholders() {
    this.PlaceholdersService.get(this.language, this.presentation.presentation_type, this.presentation.output_type, this.allocation).subscribe(
        (data)=>this.placeholders=data.data,
        (error)=>console.log(error),
        ()=>this.showPlaceholdersPicker=true
    );
  }
  onDataPlacement(placeholder) {
    this.insertPlaceholder(placeholder);
  }
  onTextPlacement(placeholder) {
    this.insertPlaceholder(placeholder);
  }
  onImagePlacement(image: any) {
    this.insertPlaceholder('image'+image.id.toString(),false);
    this.checkTemplateImage(image);
  }
  insertPlaceholder( placeholder: string, includeDelimiters: boolean=true ) {
    let delimiters=this.PlaceholdersService.getDelimiters();
    let editor=null;
    switch(this.activeEditorSection) {
      case 1: // Header
        editor=this.editorHeader;
        break;
      case 2: // Body
        editor=this.editorBody;
        break;
      case 3: // Group
        editor=this.editorGroup;
        break;
      case 4: // Row
        editor=this.editorRow;
        break;
      case 5: // Footer
        editor=this.editorFooter;
        break;
    }
    if(editor===null) return;
    let doc=editor.codeMirror.doc;
    if(includeDelimiters)
      doc.replaceSelection(delimiters.left+placeholder+delimiters.right);
    else
      doc.replaceSelection(placeholder);
    editor.codeMirror.focus();
  }
  checkTemplateImage(image: any) {
    let placeholder='image'+image.id.toString();
    if(!this.template.hasOwnProperty('images'))
      this.template.images={};
    if(!this.template.images.hasOwnProperty(placeholder))
      this.template.images[placeholder]='data:image/jpeg;base64,'+image.content;
    this.createPDF();
  }
  closePlaceholderPicker() {
    this.showPlaceholdersPicker=false;
  }
  onEditorChange(event, section) {
    this.editorError='';
    try {
      this.template[section]=JSON.parse(event);
      if(this.showPreview)
        this.createPDF();
    } catch (e) {
      this.editorError=this.TranslateService.GetTranslation('template-editor.has-errors');
    }
  }
  createPDF() {
    for(let row of this.template.content)
      if(row.hasOwnProperty('table') && row.table.hasOwnProperty('body')) {
        if(row.table.body==="<%groups%>" || row.table.body==="<%rows%>")
          row.table.body = [[]];
      }

    this.PdfService.loadPdfMaker().then( ()=>
          this.PdfService.pdfMake.createPdf(this.template).getDataUrl(function(outDoc) {
              (document.getElementById('pdfObject') as HTMLObjectElement).data = outDoc;
          })
    )
  }
  validateTemplates() {
    if(!this.isTemplateValid(this.headerStr)) return false;
    if(!this.isTemplateValid(this.bodyStr))   return false;
    if(!this.isTemplateValid(this.groupStr))  return false;
    if(!this.isTemplateValid(this.rowStr))    return false;
    if(!this.isTemplateValid(this.footerStr)) return false;
    if(!this.isTemplateValid(this.stylesStr)) return false;
    return true;
  }
  isTemplateValid(templateStr: string) {
    try {
      JSON.parse(templateStr);
      return true;
    } catch (e) {
      return false;
    }
  }
  composeTemplates() {
    this.template.header=JSON.parse(this.headerStr);
    this.template.body=JSON.parse(this.bodyStr);
    this.template.footer=JSON.parse(this.footerStr);
    this.template.styles=JSON.parse(this.stylesStr);
    this.PDFTemplate.template=this.template;
    this.PDFTemplate.group_template=JSON.parse(this.groupStr);
    this.PDFTemplate.row_template=JSON.parse(this.rowStr);
  }
  save() {
    if(!this.validateTemplates()) {
      this.JBMToastsService.error(this.TranslateService.GetTranslation('template-editor.has-errors'));
    }
    this.composeTemplates();
    if(this.PDFTemplate.id===0)
      this.PDFTemplatesService.create(this.PDFTemplate).subscribe(
          ()=>{},
          (error)=>console.error(error),
          ()=>this.afterSave()
      )
    else
      this.PDFTemplatesService.update(this.PDFTemplate).subscribe(
          ()=>{},
          (error)=>console.error(error),
          ()=>this.afterSave()
      )
  }
  afterSave() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    this.redirect();
  }
  cancel() {
    this.redirect();
  }
  redirect() {
    this.Router.navigateByUrl('settings/presentations');
  }
}
