<div class="form-group row {{class}}" [ngClass]="{'stacked': labelStacked, 'readonly': !editable}">
   <label *ngIf="labelCaption" class="{{labelClass}}" [ngClass]="{'col-form-label': !labelStacked, 'col-form-label-sm': sm}" (click)="hide()">
      {{labelCaption}}
      <span *ngIf="!editable">:</span>
      <span *ngIf="editable && required" class="asterix">*</span>
   </label>
   <div class="jbm-multi-select {{inputClass}}" [ngClass]="{'data-value': !editable}">
      <div class="container form-control"
        [ngClass]="{'form-select': editable, 'is-invalid': (submitted || touched) && required && !open && !selection.length, 'is-valid': touched && required && selection.length}"
        tabindex="0" (click)="toggle()" (keydown.enter)="toggle()" (keydown.Tab)="hide()" (blur)="blur()">
         <div *ngIf="options.length && removeableSelection" class="selection">
            <span *ngFor="let s of selection" class="mr-2" (click)="toggleOption(s); $event.stopPropagation()">
               {{getOptionValue(s)}} <i class="fa fa-times"></i>
            </span>
         </div>
         <div *ngIf="options.length && !removeableSelection" class="selection">
            <span *ngFor="let s of selection; index as index" class="mr-1">
               {{getOptionValue(s)}}  <span *ngIf="index<selection.length-1">,</span>
            </span>
         </div>
      </div>
      <div class="invalid-feedback" [ngClass]="{'is-invalid': (submitted || touched) && required && !open && !selection.length}">
         <div class="error">{{'entity.requiredfield'|translate}}</div>
      </div>
      <div [hidden]="!options.length || !open || !editable" class="options">
         <div *ngFor="let o of options; index as index" class="form-check">
            <input tabindex="-1" id="{{id+index}}" [checked]="getSelected(o.key)" type="checkbox" class="form-check-input" (click)="toggleOption(index); $event.stopPropagation()" (keydown.Tab)="toggle()">
            <label for="{{id+index}}" class="form-check-label">
               &nbsp;{{o.value}}
            </label>
         </div>
      </div>
   </div>
</div>
