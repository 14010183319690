import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

export interface SearchEvent {
  value: string;
  minlength: number;
}

@Component({
  selector: 'jbm-searchbox',
  templateUrl: './JBMSearchBox.component.html',
  styleUrls: ['./JBMSearchBox.component.scss']
})
export class JBMSearchBoxComponent implements OnInit {
  searchEvent: SearchEvent;
  focused: boolean=false;

  @Input() id: string='';
  @Input() visible: boolean;
  @Input() minlength: number;
  @Input() search: string;
  @Input() size: string='';
  @Output() searchChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if(this.id==undefined) this.id='';
    if(this.search==undefined) this.search='';
    if(this.visible==undefined) this.visible=true;
    if(this.minlength==undefined) this.minlength=2;
    if(this.size==undefined) this.size='';
  }
  keyUp(event) {
    this.search=event.target.value.trimLeft();
    this.emitChange();
  }
  onUndo() {
    this.search='';
    this.emitChange();
  }
  emitChange() {
    this.searchEvent={ value: this.search, minlength: this.minlength };
    this.searchChange.emit( this.searchEvent );
  }
  resetSearch() {
    this.search='';
  }
  @HostListener('window:keydown.control.f', ['$event'])
  handleShortcut(event: KeyboardEvent) {
      (<HTMLInputElement>document.getElementById(this.id)).focus();
      event.preventDefault();
  }
  @HostListener('window:keydown.escape', ['$event'])
  handleEscape(event: KeyboardEvent) {
    this.onUndo();
    event.preventDefault();
  }
}
