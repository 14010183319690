import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ProductionLinesService} from "../data/production-lines.service";
import {
  JBMTableColumnAlignment,
  JBMTableColumnDef,
  JBMTableColumnType
} from "../../../components/JBM/JBMTable/JBMTable.component";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {DatetimeService} from "../../../services/datetime/datetime.service";
import {QueryHttpHelper} from "../../../components/JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";

@Component({
  selector: 'production-details',
  templateUrl: './production-details.component.html',
  styleUrls: ['./production-details.component.scss']
})
export class ProductionDetailsComponent implements OnInit, OnDestroy {
  lines: any[]=[];
  charges: any[]=[];
  totals: any[]=[];
  columnDefs: JBMTableColumnDef[];
  queryHttpHelper: QueryHttpHelper;

  count$: Subscription;

  constructor(
      private ProductionLinesService: ProductionLinesService,
      private TranslateService: TranslateService,
      private DatetimeService: DatetimeService
  ) { }

  @Input() receipt_id: number;

  @ViewChild('differenceTemplate') differenceTemplate: TemplateRef<any>;

  ngOnInit(): void {
    if(this.receipt_id==undefined) this.receipt_id=0;

    this.columnDefs = [
      { name: 'stamp', type: JBMTableColumnType.date, header: this.TranslateService.GetTranslation('datetime.date'), width: 5.5 },
      { name: 'start', type: JBMTableColumnType.time, header: this.TranslateService.GetTranslation('order.starttime'), width: 4.5 },
      { name: 'stop', type: JBMTableColumnType.time, header: this.TranslateService.GetTranslation('order.endtime'), width: 4.5 },
      { name: 'charge_no', header: this.TranslateService.GetTranslation('production.charge'), width: 3.5 },
      { name: 'size', type: JBMTableColumnType.number, header: this.TranslateService.GetTranslation('production.charge')+this.TranslateService.GetTranslation('quantity.size').toLowerCase(), width: 6 },
      { name: 'dry', type: JBMTableColumnType.number, header: this.TranslateService.GetTranslation('production.dry'), width: 5 },
      { name: 'wet', type: JBMTableColumnType.number, header: this.TranslateService.GetTranslation('production.wet'), width: 5 },
      { name: 'result', type: JBMTableColumnType.number, header: this.TranslateService.GetTranslation('production.weighed'), width: 5 },
      { name: 'difference', type: JBMTableColumnType.string, align: JBMTableColumnAlignment.right, header: this.TranslateService.GetTranslation('ui.difference'), width: 9 },
      { name: 'moisture', type: JBMTableColumnType.number, header: this.TranslateService.GetTranslation('production.moisture'), width: 5 },
      { name: 'line_water_correction', type: JBMTableColumnType.number, header: this.TranslateService.GetTranslation('production.water-correction'), width: 5, className: 'pr-3' },
      { name: 'silo', header: this.TranslateService.GetTranslation('production.silo'), width: 3 },
      { name: 'code', header: this.TranslateService.GetTranslation('entity.resource') },
      { name: 'semi', type: JBMTableColumnType.boolean, align: JBMTableColumnAlignment.left, header: this.TranslateService.GetTranslation('ui.semi')+'/'+this.TranslateService.GetTranslation('ui.auto'), width: 5,
        displayValues: [
          { dataValue: false, displayValue: this.TranslateService.GetTranslation('ui.auto') },
          { dataValue: true, displayValue: this.TranslateService.GetTranslation('ui.semi') }
        ]
      },
      { name: 'alibi', type: JBMTableColumnType.number, header: this.TranslateService.GetTranslation('production.alibi'), width: 3 }
    ];
    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.paginationProps.pageSize=7;

    this.getData();
  }
  getData(countRows:boolean=true) {
    let params=this.queryHttpHelper.getHttpParams(false);
    params=params.append('charges','1').append('totals','1');
    this.ProductionLinesService.get(this.receipt_id,params).subscribe(
        (data)=>{
          this.lines=data.data.lines;
          this.charges=data.data.charges;
          this.totals=data.data.totals;
        },
        (error)=>console.error(error),
        ()=> {

          this.columnDefs[8].template = this.differenceTemplate;

          for(let charge of this.charges)
            charge.start=this.DatetimeService.timeStrFromUnixTime(charge.start);

          if(countRows) {
            params=params.append('count','1');
            this.count$=this.ProductionLinesService.get(this.receipt_id, params).subscribe(
                (data: any)=>this.queryHttpHelper.paginationProps.itemCount=data.data,(error)=>console.error(error) );
          }
        }
    )
  }
  // Event handling
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page = page;
    this.getData(false);
  }
  ngOnDestroy(): void {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
