<form [formGroup]="form" (ngSubmit)="save()" class="order" xmlns="http://www.w3.org/1999/html">
   <app-header>
      <jbm-view-title [caption]="caption" [parentLinkCaption]="project.name" parentLinkSlug="projects/{{project.id}}"></jbm-view-title>
      <div class="float-end toolbar action-buttons">
        <jbm-save-button subject="{{'project.order'|translate}}" [small]="false" (click)="save()"></jbm-save-button>
        <jbm-cancel-button context="light" [small]="false" (click)="cancel()"></jbm-cancel-button>
        <div class="toolbar-spacer-1"></div>
        <jbm-help-button [helpTopicID]="0"></jbm-help-button>
      </div>
   </app-header>
   <section class="module order padding padding-top">
      <div class="split-container clearfix">
         <div class="left-side">
            <jbm-text-input [formControl]="formControls.description" [submitted]="isSubmitted" [editable]="editable"
              [id]="'description'" class="mt-3 lg" labelCaption="{{'entity.description'|translate}}"
              labelClass="lg">
            </jbm-text-input>
            <jbm-select
              [formControl]="formControls.worktypes_id" [submitted]="isSubmitted" [editable]="editable"
              [required]="true" [options]="worktypeOptions" [id]="'worktypes_id'" insertLeadingOption="true" class="lg"
              labelClass="lg" labelCaption="{{'order.worktype'|translate}}">
               <jbm-input-error [formControl]="formControls.worktypes_id" [submitted]="isSubmitted">
                  {{'entity.requiredfield'|translate}}
               </jbm-input-error>
            </jbm-select>
            <jbm-textarea [formControl]="formControls.comments" [submitted]="isSubmitted" [editable]="editable"
              [id]="'comments'" class="mt-3 lg comments" labelCaption="{{'entity.notes'|translate}}"
              labelClass="lg" rows="3">
            </jbm-textarea>
            <div class="form-section-separator lg-lg mb-0 ">{{'entity.recipe'|translate}}</div>
            <jbm-check [formControl]="formControls.recipe_unknown" [id]="'recipe_unknown'"
              [labelCaption]="'order.recipe-unknown'|translate" class="xl"
              labelClass="lg" submitted="submitted" (change)="recipeUnknownChange()">
            </jbm-check>
            <recipe-selector *ngIf="!recipeUnknown"
              [labelCaption]="'entity.recipe'|translate"
              labelClass="lg"
              class="lg mb-2"
              [template]="recipeselect"
              [recipe_id]="recipe_id"
              [environment_id]="environment_id"
              (recipeSelected)="selectRecipeEnvironment($event)">
               <ng-template #recipeselect let-recipe="recipe" let-environment="environment">
                  <div *ngIf="recipe && environment">
                     <table class="table table-xs table-borderless">
                        <tr><td>{{'entity.code'|translate}}:</td><td>{{recipe.code}}</td></tr>
                        <tr><td>{{'concrete.consistency'|translate}}:</td><td>{{recipe.consistency.code}}</td></tr>
                        <tr *ngIf="recipe.strength"><td>{{'concrete.strength'|translate}}:</td><td>{{recipe.strength.description}}</td></tr>
                        <tr><td>{{'concrete.environment'|translate}}:</td><td>{{environment.description}}</td></tr>
                     </table>
                  </div>
               </ng-template>
            </recipe-selector>
            <legend *ngIf="recipeUnknown" class="ml-4">{{'financial.concrete-specs'|translate}}</legend>
            <div *ngIf="recipeUnknown">
               <strength-selector
                 [labelCaption]="'concrete.strength'|translate"
                 labelClass="lg"
                 class="lg"
                 [id]="'strength'"
                 [template]="strengthselect"
                 [strength_id]="strength_id"
                 (strengthSelected)="selectStrength($event)">
                  <ng-template #strengthselect let-strength="strength">
                     <div *ngIf="strength">
                        {{strength.description}}
                     </div>
                  </ng-template>
               </strength-selector>
               <environment-selector
                 [labelCaption]="'concrete.environment'|translate"
                 labelClass="lg"
                 class="lg"
                 [id]="'environment'"
                 [template]="environmentselect"
                 [environment_id]="environment_id"
                 (environmentSelected)="selectEnvironment($event)">
                  <ng-template #environmentselect let-environment="environment">
                     <div *ngIf="environment">
                        {{environment.description}}
                     </div>
                  </ng-template>
               </environment-selector>
               <consistency-selector
                 [labelCaption]="'concrete.consistency'|translate"
                 labelClass="lg"
                 class="lg"
                 [id]="'consistency'"
                 [template]="consistencyselect"
                 [consistency_id]="consistency_id"
                 (consistencySelected)="selectConsistency($event)">
                  <ng-template #consistencyselect let-consistency="consistency">
                     <div *ngIf="consistency">
                        {{consistency.description}}
                     </div>
                  </ng-template>
               </consistency-selector>
            </div>
            <jbm-static-label *ngIf="financialRights.AllowRead && concretePrice" labelCaption="{{'financial.concrete-price'|translate}}"
               labelClass="lg" [value]="">
               <jbm-pricerules-breakdown-popover [isConcrete]="true" [price]="concretePrice" [pricerules]="priceInfo.pricerules"></jbm-pricerules-breakdown-popover>
            </jbm-static-label>
            <jbm-textarea [formControl]="formControls.recipe_comments" [submitted]="isSubmitted"
               [editable]="editable" [id]="'recipe_comments'" class="lg"
               labelCaption="{{'entity.notes'|translate}}" labelClass="lg" rows="3">
            </jbm-textarea>
         </div>
         <div class="right-side">
            <div class="form-section-separator mt-4">{{'order.production'|translate}}</div>
            <jbm-float-input-group [formControl]="formControls.amount" [submitted]="isSubmitted" [editable]="editable"
              [id]="'amount'" required="true" [inputClass]="'xxs'" append="{{'quantity.m3'|translate}}"
              labelCaption="{{'order.amount'|translate}}" labelClass="lg" decimals="1" [decimalsOptional]="true"
              [negativeAllowed]="false" (onBlur)="calcEndTime();updateAmount();">
               <jbm-input-error [formControl]="formControls.amount" [submitted]="isSubmitted">
                  {{'entity.concrete-amount-format'|translate}}
               </jbm-input-error>
            </jbm-float-input-group>
            <jbm-select [formControl]="formControls.unloading_methods_id" [submitted]="isSubmitted" [editable]="editable"
              [options]="unloading_methodOptions" insertLeadingOption="true" [required]="true" [id]="'unloading_methods_id'"
              class="sm" labelClass="lg" labelCaption="{{'order.unloading-method'|translate}}">
               <jbm-input-error [formControl]="formControls.unloading_methods_id" [submitted]="isSubmitted">
                  {{'entity.requiredfield'|translate}}
               </jbm-input-error>
            </jbm-select>
            <jbm-float-input-group [formControl]="formControls.unloading_flow" [submitted]="isSubmitted" [editable]="editable"
              [id]="'unloading_flow'" inputClass="width-9" class="xs" labelCaption="{{'order.unloading-flow'|translate}}"
              append="{{'quantity.m3'|translate}}/{{'datetime.hour'|translate}}" labelClass="lg" decimals="2" [decimalsOptional]="true"
              [negativeAllowed]="false" (onBlur)="calcEndTime()">
               <jbm-input-error [formControl]="formControls.unloading_flow" [submitted]="isSubmitted">
                  {{'entity.concrete-amount-format'|translate}}
               </jbm-input-error>
            </jbm-float-input-group>
            <div *ngIf="planningRights.AllowCreate || planningRights.AllowUpdate">
               <div class="form-section-separator mt-4">{{'order.production'|translate}} {{'entity.planning'|translate|lowercase}}</div>
               <ngb-alert *ngIf="!project.traveltime" [type]="'warning'" [dismissible]="false">{{'order.no-traveltime'|translate}}</ngb-alert>
               <jbm-date-input labelClass="lg" labelCaption="{{'order.production-date'|translate}}"
                 [formControl]="formControls.production_date" [maxdate]="maxdate"
                  placement="bottom">
                  <jbm-input-error [required]="false" [formControl]="formControls.production_date" [submitted]="isSubmitted">
                     <span *ngIf="formControls['production_date'].hasError('dateInvalid')">
                       {{'entity.dateformat'|translate}}
                     </span>
                  </jbm-input-error>
               </jbm-date-input>
               <div *ngIf="project.traveltime">
                  <jbm-time-input [formControl]="formControls.starttime" [submitted]="isSubmitted"
                    [id]="'starttime'" labelCaption="{{'order.starttime'|translate}}" labelClass="lg"
                    (onBlur)="calcEndTime(); calcLoadTime()">
                     <jbm-input-error [formControl]="formControls.starttime" [submitted]="isSubmitted">
                        {{'entity.timeformat'|translate}}
                     </jbm-input-error>
                  </jbm-time-input>
                  <jbm-static-label *ngIf="loadTime" labelClass="lg" [labelCaption]="'order.loadtime'|translate" [value]="loadTime"> {{'datetime.hour'|translate}}</jbm-static-label>
                  <jbm-static-label *ngIf="endTime" labelClass="lg" [labelCaption]="'order.endtime'|translate" [value]="endTime"> {{'datetime.hour'|translate}}</jbm-static-label>
               </div>
               <div *ngIf="!project.traveltime">
                  <jbm-time-input [formControl]="formControls.loadtime" [submitted]="isSubmitted"
                    [id]="'loadtime'" labelCaption="{{'order.loadtime'|translate}}" labelClass="lg">
                     <jbm-input-error [formControl]="formControls.loadtime" [submitted]="isSubmitted">
                        {{'entity.timeformat'|translate}}
                     </jbm-input-error>
                  </jbm-time-input>
               </div>
               <jbm-check [formControl]="formControls.on_call" [id]="'on_call'" class="mt-3"
                 labelCaption="{{'order.on-call'|translate}}" labelClass="lg" inputClass="lg">
               </jbm-check>
            </div>

            <div *ngIf="financialRights.AllowRead && amount && concretePrice">
               <div class="form-section-separator mt-4">{{'ui.total'|translate}}{{'financial.prices'|translate|lowercase}}</div>
               {{'financial.concrete'|translate}}: <span class="fw-bold mr-4">{{concretePrice*amount|money}}</span>
               <span *ngIf="articlesTotalPrice">
                  {{'financial.articles'|translate}}: <span class="fw-bold">{{articlesTotalPrice|money}}</span>
               </span>
            </div>
         </div>
      </div>
      <order-articles #order_articles
        [project_id]="project.id"
        [customer_id]="project.customer_id"
        [order_id]="order.id"
        [order_amount]="amount"
        [language]="language"
        [showPrices]="true"
        (onTotalPriceChange)="updateArticlesTotalPrice($event)">
      </order-articles>
    </section>
</form>

