import {Component, OnInit, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";
import {ProjectsService } from "../../data/projects.service";
import {OrdersService } from "../../data/orders.service";
import {RecipesService} from "../../data/recipes.service";
import {WorktypesService} from "../../data/worktypes.service";
import {UnloadingMethodsService} from "../../data/unloading-methods.service";
import {ConcretePricesService} from "../../../financial/data/concrete-prices.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {PriceService} from "../../../../services/price/price.service";
import {DatetimeService} from "../../../../services/datetime/datetime.service";
import {ValidationPatternsService} from "../../../../services/validation/patterns.service";
import {SanitizationService} from "../../../../services/sanitization/sanitization.service";
import {HttpParams} from "@angular/common/http";
import {forkJoin} from "rxjs";
import {Order} from "../../data/interfaces/order";
import {Project} from "../../data/interfaces/project";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {GroupRights} from "../../../users/data/interfaces/grouprights";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {OrderArticlesComponent} from "../order-articles/order-articles.component";

@Component({
  selector: 'form-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})

export class OrderComponent implements OnInit, AfterViewInit {
  project: Project;
  order: Order;
  editable: boolean=false;
  userRights: GroupRights;
  planningRights: GroupRights;
  financialRights: GroupRights;
  language: string=this.TokenService.getLanguage();
  caption: string;
  recipe: any= { strengths_id: null, consistencies_id: null };

  recipeUnknown: boolean=false;
  recipe_id: number=null;
  environment_id: number=0;
  strength_id: number=0;
  consistency_id: number=0;

  worktypes: any[]=[];
  worktypeOptions: any[]=[];
  unloading_methods: any[]=[];
  unloading_methodOptions: any[]=[];

  amount: number=0;
  loadingTime: number;
  endTime: string;
  loadTime: string;
  date: NgbDateStruct;
  maxdate: NgbDateStruct;

  priceInfo: any={price: 0.0, adjustment: 0.0, pricerules: []};
  concretePrice: number=0.0;
  concretePriceAdjustment: number=0.0;
  concretePriceAsString: string;
  articlesTotalPrice: number=0;

  constructor(
      private Router: Router,
      private Location: Location,
      private ActivatedRoute: ActivatedRoute,
      private TokenService: TokenService,
      private formBuilder: FormBuilder,
      private ProjectsService: ProjectsService,
      private OrdersService: OrdersService,
      private RecipesService: RecipesService,
      private WorktypesService: WorktypesService,
      private UnloadingMethodsService: UnloadingMethodsService,
      private ConcretePricesService: ConcretePricesService,
      private TranslateService: TranslateService,
      private PriceService: PriceService,
      private DatetimeService: DatetimeService,
      private SanitizationService: SanitizationService,
      private ValidationPatternsService: ValidationPatternsService,
      private JBMToastsService: JBMToastsService
  ) {
    // The route resolver has fetched the project data
    this.project=this.ActivatedRoute.snapshot.data.project.data;

    if(this.Router.url.indexOf('create')>0) {
      // Get empty order data
      this.order=this.OrdersService.getEmpty();
      this.caption=this.TranslateService.GetTranslation('order.create');
    } else {
      // The route resolver has fetched the order data
      this.order=this.ActivatedRoute.snapshot.data.order.data;
      this.caption=this.TranslateService.GetTranslation('order.update');
    }
    // Amount input for the order articles component
    this.amount=this.order.amount;
  }

  @ViewChild('order_articles') order_articles: OrderArticlesComponent;

  form: FormGroup;
  isSubmitted: boolean=false;

  ngOnInit(): void {
    this.userRights=this.TokenService.getRightsByName('projects');
    this.planningRights=this.TokenService.getRightsByName('planning');
    this.financialRights=this.TokenService.getRightsByName('financial');
    this.editable=this.userRights.AllowCreate || this.userRights.AllowUpdate;

    if(this.order.id===0)
      this.caption=this.TranslateService.GetTranslation('order.create');
    else
      this.caption=this.TranslateService.GetTranslation('order.update');

    this.endTime='';
    this.loadTime='';
    this.loadingTime=0;

    this.maxdate={ year: new Date().getFullYear()+1, month: 12, day: 31 };
    this.date=null;

    this.concretePriceAsString='';

    this.addControls();

    let ltObservable=this.OrdersService.getLoadingTime();
    let wtObservable=this.WorktypesService.get(this.language);
    let ulsObservable=this.UnloadingMethodsService.get(this.language);
    forkJoin([ltObservable, wtObservable, ulsObservable]).subscribe(results => {
      this.loadingTime=results[0].data;
      this.worktypes=results[1].data;
      this.unloading_methods=results[2].data;

      this.setControlValues();
      this.calculateConcretePrice();
    });
  }
  ngAfterViewInit(): void {
    document.getElementById('description').focus();
  }
  addControls() {
    this.form=this.formBuilder.group({
      description: new FormControl(''),
      recipes_id: new FormControl(''),
      recipe_unknown: new FormControl(false),
      consistencies_id: new FormControl(''),
      environments_id: new FormControl(''),
      strengths_id: new FormControl(''),
      amount: new FormControl('',[Validators.pattern(this.ValidationPatternsService.floatPattern(2))]),
      delivered: new FormControl('',[Validators.pattern(this.ValidationPatternsService.floatPattern(1))]),
      worktypes_id: new FormControl('',[Validators.required]),
      comments: new FormControl(''),
      recipe_comments: new FormControl(''),
      unloading_methods_id: new FormControl('',[Validators.required]),
      unloading_flow: new FormControl('',[Validators.pattern(this.ValidationPatternsService.floatPattern(1))]),
      production_date: new FormControl(this.date),
      loadtime: new FormControl(''),
      starttime: new FormControl('',[Validators.pattern(this.DatetimeService.getTimeRegEx())]),
      on_call: new FormControl(false),
    }, {updateOn: "blur"});

    if(!this.project.traveltime)
      // Manual loadtime input when no project traveltime available
      this.formControls.loadtime.addValidators([Validators.pattern(this.DatetimeService.getTimeRegEx())]);
  }
  get formControls() {
    return this.form.controls;
  }
  setControlValues() {
    if(this.order.id) {
      this.order.starttime=this.order.starttime===null ? '' : this.order.starttime;
      this.order.loadtime=this.order.loadtime===null ? '' : this.order.loadtime;
    }

    this.worktypeOptions=this.WorktypesService.getSelectOptions(this.worktypes,true);
    this.unloading_methodOptions=this.UnloadingMethodsService.getSelectOptions(this.unloading_methods,true);

    this.form.controls.description.setValue(this.order.description);
    this.form.controls.recipes_id.setValue(this.order.recipes_id===null ? '' : this.order.recipes_id.toString());
    this.form.controls.environments_id.setValue(this.order.environments_id===null ? '' : this.order.environments_id.toString());
    this.form.controls.consistencies_id.setValue(this.order.consistencies_id===null ? '' : this.order.consistencies_id.toString());
    this.form.controls.strengths_id.setValue(this.order.strengths_id===null ? '' : this.order.strengths_id.toString());
    this.form.controls.amount.setValue(
        this.order.amount==0 ? '' : this.order.amount.toString().replace('.', ','));
    this.form.controls.delivered.setValue(
        this.order.delivered==0 ? '' : this.order.delivered.toString().replace('.', ','));
    this.form.controls.worktypes_id.setValue(this.order.worktypes_id===0 ? '' : this.order.worktypes_id.toString());
    this.form.controls.unloading_methods_id.setValue(this.order.unloading_methods_id===0 ? '' : this.order.unloading_methods_id.toString());
    this.form.controls.unloading_flow.setValue(
        this.order.unloading_flow===null ? '' : this.order.unloading_flow.toString().replace('.', ',') );
    this.form.controls.production_date.setValue(this.order.production_date===null ? null : this.order.production_date);
    this.form.controls.starttime.setValue(this.order.starttime===null ? '' : this.order.starttime);
    this.form.controls.loadtime.setValue(this.order.loadtime===null ? '' : this.order.loadtime);
    this.form.controls.comments.setValue(this.order.comments===null ? '' : this.order.comments);
    this.form.controls.recipe_comments.setValue(this.order.recipe_comments===null ? '' : this.order.recipe_comments);
    this.form.controls.on_call.setValue(this.order.on_call);

    if(!this.order.id)
      this.recipeUnknown=false;
    else {
      this.recipe_id=this.order.recipes_id===null ? null : this.order.recipes_id;
      this.recipeUnknown=this.recipe_id===null;
    }
    this.form.controls.recipe_unknown.setValue(this.recipeUnknown);

    this.strength_id=this.order.strengths_id;
    this.consistency_id=this.order.consistencies_id;
    this.environment_id=this.order.environments_id;

    this.calcLoadTime();
    this.calcEndTime();
  }
  selectRecipeEnvironment(event) {
    this.recipe_id=event.recipe_id;
    this.environment_id=event.environment_id;
    this.formControls.recipes_id.setValue(this.recipe_id.toString());
    this.formControls.environments_id.setValue(this.environment_id.toString());
    this.setRecipe(event.recipe_id);
    this.order_articles.setRecipe(event.recipe_id);
  }
  setRecipe(id: number) {
    let params=new HttpParams().append('concrete-specifications','1');
    this.RecipesService.getOne(id, this.language, params).subscribe(
        (data)=>{
          this.recipe=data.data;
          this.formControls.strengths_id.setValue(this.recipe.strengths_id.toString());
          this.formControls.consistencies_id.setValue(this.recipe.consistencies_id.toString());
        },(error)=>console.error(error),
        ()=>this.calculateConcretePrice()
    )
  }
  selectStrength(event) {
    this.formControls.strengths_id.setValue(event.id===null ? '' : event.id.toString());
    this.strength_id=event.id;
    this.calculateConcretePrice();
  }
  selectEnvironment(event) {
    this.formControls.environments_id.setValue(event.id===null ? '' : event.id.toString());
    this.environment_id=event.id;
    this.calculateConcretePrice();
  }
  selectConsistency(event) {
    this.formControls.consistencies_id.setValue(event.id===null ? '' : event.id.toString());
    this.consistency_id=event.id;
    this.calculateConcretePrice();
  }
  recipeUnknownChange() {
    this.recipeUnknown=!this.recipeUnknown;
    if(this.recipeUnknown) {
      this.order_articles.removeRecipeArticles();
      return true;
    }
    if(this.recipe_id!==null)
      this.order_articles.setRecipe(this.recipe_id);
    this.calculateConcretePrice();
  }
  updateAmount() {
    this.amount=parseFloat(this.formControls.amount.value);
    if(isNaN(this.amount))
      this.amount=0;
    this.order_articles.setOrderAmount(this.amount);
  }
  calcLoadTime() {
    this.loadTime='';
    if(!this.form.controls.starttime.valid) return;
    let starttime=this.form.controls.starttime.value.trim();
    if(starttime!=='')
      this.loadTime=this.OrdersService.calcLoadTime(starttime, this.project.traveltime, this.loadingTime );
  }
  calcEndTime() {
    this.endTime='';

    if(!this.form.controls.starttime.valid) return;
    if(!this.form.controls.amount.valid) return;
    if(!this.form.controls.unloading_flow.valid) return;

    let starttime=this.form.controls.starttime.value.trim();
    if(starttime==='') return;
    let amount=parseFloat(this.form.controls.amount.value);
    if(isNaN(amount)) return;
    if(Math.round(amount)===0) return;
    let unloading_flow=parseFloat(this.form.controls.unloading_flow.value);
    if(isNaN(unloading_flow)) return;
    if(Math.round(unloading_flow)===0) return;

    this.endTime=this.OrdersService.calcEndTime(starttime, amount, unloading_flow);
  }
  calculateConcretePrice() {
    let params=new HttpParams()
        .append('plants_id',this.project.plants_id===null ? '0' : this.project.plants_id.toString())
        .append('projects_id',this.project.id.toString())
        .append('customers_id',this.project.customer_id===null ? '0' : this.project.customer_id.toString())
        .append('environments_id',this.formControls.environments_id.value==='' ? '0' : this.formControls.environments_id.value.toString())
        .append('strengths_id',this.formControls.strengths_id.value==='' ? '0' : this.formControls.strengths_id.value.toString())
        .append('consistencies_id',this.formControls.consistencies_id.value==='' ? '0' : this.formControls.consistencies_id.value.toString());

    this.priceInfo={price: 0.0, adjustment: 0.0};
    this.ConcretePricesService.calculatePrice(params).subscribe(
        (data)=>this.priceInfo=data.data,
        (error)=>console.error(error),
        ()=> {
          this.concretePrice=this.priceInfo.price;
          this.concretePriceAdjustment=this.priceInfo.adjustment;
          this.concretePriceAsString=this.PriceService.formatPrice(this.concretePrice);
        }
    )
  }
  updateArticlesTotalPrice(event) {
    this.articlesTotalPrice=event.totalPrice;
  }

  save() {
    this.isSubmitted=true;
    if(this.form.invalid)
      return;

    // Sanitize data
    let recipes_id=null;
    if(this.formControls.recipes_id.value!=='')
      recipes_id=parseInt(this.formControls.recipes_id.value);
    let amount=parseFloat(this.formControls.amount.value.replace(',','.'));
    let delivered=this.SanitizationService.checkAmountFloatStr(this.formControls.delivered.value,0.0);
    let unloading_methods_id=this.SanitizationService.checkAmountIntegerStr(this.formControls.unloading_methods_id.value,0);
    let unloading_flow=this.SanitizationService.checkAmountFloatStr(this.formControls.unloading_flow.value,0.0);
    if(unloading_flow===0) unloading_flow=null;

    this.loadTime=null;
    if(!this.project.traveltime)
      this.loadTime=this.formControls.loadtime.value;
    let starttime=null;
    if(this.project.traveltime)
      this.formControls.starttime.value.trim();
    let production_date=this.formControls.production_date.value;
    if(production_date===null) {
      // Empty production date; clear production times
      starttime=null;
      this.loadTime=null;
    } else {
      production_date=production_date.trim();
      if(production_date==='') {
        // Empty starttime or production date; clear production times
        starttime=null;
        production_date=null;
        this.loadTime=null;
      }
      if(production_date.length===10)
        // Convert to YYYY-MM-DD
        production_date=this.DatetimeService.dateDMYToYMD(production_date);
    }

    let data={
      id: this.order.id,
      projects_id: this.project.id,
      description: this.formControls.description.value,
      recipes_id: this.recipeUnknown ? null : recipes_id,
      strengths_id: parseInt(this.formControls.strengths_id.value),
      environments_id: parseInt(this.formControls.environments_id.value),
      consistencies_id: parseInt(this.formControls.consistencies_id.value),
      amount: amount,
      delivered: delivered,
      concrete_price: this.concretePrice,
      price_adjustment: this.concretePriceAdjustment,
      worktypes_id: parseInt(this.formControls.worktypes_id.value),
      unloading_methods_id: unloading_methods_id,
      unloading_flow: unloading_flow,
      production_date: production_date,
      starttime: starttime,
      loadtime: this.loadTime,
      comments: this.formControls.comments.value,
      recipe_comments: this.formControls.recipe_comments.value,
      on_call: this.formControls.on_call.value
    }

    if(this.order.id===0) {
      this.OrdersService.create(data, this.language).subscribe(
          (data)=>this.order.id=data.data,(error)=>console.error(error),()=>{
            this.order_articles.save(this.order.id);
            this.afterSave()
          } )
    } else {
      this.OrdersService.update(data, this.language).subscribe(
          ()=>{},(error)=>console.error(error),()=>{
            this.order_articles.save(this.order.id);
            this.afterSave()
          })
    }
  }
  afterSave() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    this.Location.back();
  }
  cancel() {
    this.Location.back();
  }
}
