import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {HttpParams} from "@angular/common/http";
import {CompanyService} from "../../data/company.service";
import {ArticlePricelistsService} from "../../../financial/data/article-pricelists.service";
import {ResourcePricelistsService} from "../../../financial/data/resource-pricelists.service";
import {RecipesService} from "../../../recipes/data/recipes.service";
import {UnloadingMethodsService} from "../../../projects/data/unloading-methods.service";
import {MailSettingsUsesService, subjects} from "../../../../services/storage/mail_settings_uses.service";
import {presentation_types} from "../../../presentations/data/presentation-types.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {PriceService} from "../../../../services/price/price.service";
import {setting_subjects, settings, SettingsService} from "../../../../services/storage/settings.service";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {ModalService} from "../../../../components/JBM/JBMModal/service/modal.service";
import {JBMModalHeaderButton} from "../../../../components/JBM/Forms/JBMModalHeader/JBMModalHeader.component";
import {GroupRights} from "../../../users/data/interfaces/grouprights";
import {forkJoin} from "rxjs";

@Component({
  selector: 'form-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss']
})

export class CompanySettingsComponent implements OnInit {
  data: any;
  editable: boolean=false;
  language: string;
  buttons: JBMModalHeaderButton[]=[];
  form: FormGroup;
  isSubmitted: boolean=false;
  userRights: GroupRights;
  userRightsRecipes: GroupRights;
  userRightsFinancial: GroupRights;
  baseRecipe: any={ description: '' };
  unloading_methods: any[]=[];
  unloading_methodOptions: any[]=[];
  resources_based_invoicing: boolean=false;
  warning: string='';

  constructor(
    private Router: Router,
    private formBuilder: FormBuilder,
    private CompanyService: CompanyService,
    public ArticlePricelistsService: ArticlePricelistsService,
    public ResourcePricelistsService: ResourcePricelistsService,
    private RecipesService: RecipesService,
    private UnloadingMethodsService: UnloadingMethodsService,
    private MailSettingsUsesService: MailSettingsUsesService,
    public TranslateService: TranslateService,
    private PriceService: PriceService,
    private SettingsService: SettingsService,
    private JBMToastsService: JBMToastsService,
    private ModalService: ModalService,
    private TokenService: TokenService,
  ) {
    this.userRights = this.TokenService.getRightsByName('company');
    this.userRightsFinancial = this.TokenService.getRightsByName('financial');
    this.userRightsRecipes = this.TokenService.getRightsByName('recipes');

    this.editable = this.userRights.AllowCreate || this.userRights.AllowUpdate;
    this.language = this.TokenService.getLanguage();
    this.addControls();
  }

  @ViewChild('modalRecipe') modalRecipe: ElementRef;

  @Output() afterSave=new EventEmitter();

  ngOnInit(): void {
    this.initData();
  }
  initData() {
    this.buttons.push( { name: 'history', visible: true, enabled: true,
      caption: this.TranslateService.GetTranslation('entity.history'), iconclass: 'fa fa-history' } );
    this.getData();
  }
  getData() {
    this.CompanyService.getData().subscribe(
        (data)=>this.data=data.data,(error)=>console.error(error),()=> {
          this.UnloadingMethodsService.get(this.language).subscribe(
              (data)=>{
                this.unloading_methods=data.data
                this.unloading_methodOptions=this.UnloadingMethodsService.getSelectOptions(this.unloading_methods,true);
              },
              (error)=>console.error(error),
              ()=> {
                // Get company level presentation-type dependent mail settings uses
                let quotObservable=this.MailSettingsUsesService.get(subjects.company,0, presentation_types.quotation);
                let quotConfObservable=this.MailSettingsUsesService.get(subjects.company,0, presentation_types.quotationConfirmation);
                let invObservable=this.MailSettingsUsesService.get(subjects.company,0, presentation_types.invoice);
                forkJoin([quotObservable, quotConfObservable, invObservable]).subscribe(results => {
                      this.form.controls.quotation_mail_settings_id.setValue(results[0].mail_settings_id.toString());
                      this.form.controls.quotation_confirmation_mail_settings_id.setValue(results[1].mail_settings_id.toString());
                      this.form.controls.invoicing_mail_settings_id.setValue(results[2].mail_settings_id.toString());
                    },
                    (error)=>console.error(error),
                    ()=> {
                      this.setControlValues();

                      let setting=false;
                      this.SettingsService.getSetting(0, setting_subjects.app,0, settings.resources_based_invoicing).subscribe(
                          (data)=>setting=data.setting,
                          (error)=>console.error(error),
                          ()=> {
                            this.resources_based_invoicing=setting===null ? false: setting;
                            this.getBaseRecipe();
                          }
                      );
                    });
              }
          )
        }
    )
  }
  getBaseRecipe() {
    let params=new HttpParams().append('concrete_specs','1').append('language', this.language);
    this.RecipesService.getBaseRecipe(params).subscribe(
      (data)=>this.baseRecipe=data,
      (error)=>console.error(error),
      ()=>this.setControlValues()
    )
  }
  addControls() {
    this.form = this.formBuilder.group({
      baseprice: new FormControl('',[this.PriceService.priceValidator(this.PriceService,false,true)]),
      article_pricelists_id: new FormControl(0,[Validators.required]),
      concrete_pricelists_id: new FormControl(0,[Validators.required]),
      resource_pricelists_id: new FormControl(0),
      max_traveltime: new FormControl('',[Validators.pattern('[0-9]+$'), Validators.min(15), Validators.max(460)]),
      unloading_methods_id: new FormControl('',[Validators.required]),
      quotation_mail_settings_id: new FormControl('',[Validators.required]),
      quotation_confirmation_mail_settings_id: new FormControl('',[Validators.required]),
      invoicing_mail_settings_id: new FormControl('',[Validators.required])
    }, { updateOn: "blur" } );
  }

  get formControls() { return this.form.controls; }

  setControlValues() {
    this.form.controls.baseprice.setValue(this.PriceService.formatPrice(this.data.baseprice));
    this.form.controls.article_pricelists_id.setValue(this.data.article_pricelists_id);
    this.form.controls.concrete_pricelists_id.setValue(this.data.concrete_pricelists_id);
    this.form.controls.resource_pricelists_id.setValue(this.data.resource_pricelists_id);
    this.form.controls.max_traveltime.setValue(this.data.max_traveltime.toString());
    this.form.controls.unloading_methods_id.setValue(this.data.unloading_methods_id===null ? '' : this.data.unloading_methods_id.toString());
  }
  setConcretePricelist(pricelist) {
    this.form.controls.concrete_pricelists_id.setValue(pricelist.id);
  }
  setArticlePricelist(pricelist) {
    this.form.controls.article_pricelists_id.setValue(pricelist.id);
  }
  setResourcePricelist(pricelist) {
    this.form.controls.resource_pricelists_id.setValue(pricelist.id);
  }
  setResourcesBasedInvoicing(event) {
    this.resources_based_invoicing=event.target.checked;
  }
  setQuotationSMTP(mail_settings) {
    this.form.controls.quotation_mail_settings_id.setValue(mail_settings.id);
  }
  setInvoicingSMTP(mail_settings) {
    this.form.controls.invoicing_mail_settings_id.setValue(mail_settings.id);
  }
  setQuotationConfirmationSMTP(mail_settings) {
    this.form.controls.quotation_confirmation_mail_settings_id.setValue(mail_settings.id);
  }
  // Base Recipe
  showRecipe() {
    this.ModalService.open(this.modalRecipe,'recipeDetails');
  }
  closeRecipe() {
    this.ModalService.close('recipeDetails');
  }
  save() {
    this.isSubmitted = true;
    this.warning='';

    if(this.form.invalid)
      return;

    let resource_pricelists_id=null;
    if(this.resources_based_invoicing) {
      resource_pricelists_id=parseInt(this.formControls.resource_pricelists_id.value);
      if(!resource_pricelists_id) {
        this.warning=this.TranslateService.GetTranslation('resources.select-pricelist');
        return;
      }
    }

    // Store company
    let data = {
      id: this.data.id,
      article_pricelists_id: parseInt(this.formControls.article_pricelists_id.value),
      concrete_pricelists_id: parseInt(this.formControls.concrete_pricelists_id.value),
      resource_pricelists_id: resource_pricelists_id,
      baseprice: this.PriceService.getPriceFromStr(this.formControls.baseprice.value),
      max_traveltime: parseInt(this.form.controls.max_traveltime.value),
      unloading_methods_id: parseInt(this.form.controls.unloading_methods_id.value),
    }
    this.CompanyService.update(data).subscribe(
        ()=>{},error=>console.error(error),()=> {

          let data=[
            { subject: subjects.company,
              subject_id: 0,
              presentation_type: presentation_types.quotation,
              mail_settings_id: parseInt(this.form.controls.quotation_mail_settings_id.value)
            },
            { subject: subjects.company,
              subject_id: 0,
              presentation_type: presentation_types.quotationConfirmation,
              mail_settings_id: parseInt(this.form.controls.quotation_confirmation_mail_settings_id.value)
            },
            { subject: subjects.company,
              subject_id: 0,
              presentation_type: presentation_types.invoice,
              mail_settings_id: parseInt(this.form.controls.invoicing_mail_settings_id.value)
            },
          ];
          this.MailSettingsUsesService.save(data).subscribe(
              ()=>{},(error)=>console.error(error),()=> {
                this.showToast();
                this.redirect();
              }
          )
        })
  }
  showToast() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
  }
  cancel() {
    this.redirect();
  }
  redirect() {
    this.Router.navigateByUrl('settings/company/settings');
  }
}
